import React, { FC, useMemo, useState } from "react";
import axios from "axios";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
import { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Row, Typography, Input, Image, Upload, Table, Spin, Modal, Tooltip } from "antd";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { FolderOutlined, SearchOutlined, EditOutlined, DeleteOutlined, UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";
import { getDocuments, getFileManagementDocuments, handleCreateFolder, uploadFilesToFMS } from "../../../../Store/requests/_semanticSearchRequests";
import _localStorageService from "../../../../Services/_localStorageService";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { ComponentFileProps, Folder, SEMANTIC_SEARCH_CARD, SEMANTIC_SEARCH_MODULES } from "../Core/constants";
import { FileForChatWithDoc, setFileSummariserStatus, setLandingScreen, setSearchType } from "../../../../Store/actions/_semanticSearchActions";
import { StringGradients } from "antd/es/progress/progress";

const { Paragraph } = Typography;

interface LoadDataType {
  isLoading: boolean;
  message: string;
};
interface ComponentChatProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleAdvancedSearchClose: () => void;
  setIsArtifactFile?: React.Dispatch<React.SetStateAction<string>>;
  setLoadData?:React.Dispatch<React.SetStateAction<LoadDataType>>;
  loadData?:LoadDataType;
  componentUseCase?:string;
}

export const ChatModalScreen: React.FC<ComponentChatProps> = ({ loadData, setIsModalVisible, handleAdvancedSearchClose,setIsArtifactFile,setLoadData ,componentUseCase}) => {
  const { activeModule,    editReportGenerationScreen,
  } = useSelector((state: any) => state?.semanticSearchReducer);
  const [folderData, setFolderData] = useState<any[]>([]);
  const [loadingData, setIsLoadingFolderData] = useState<boolean>(true);
  const apiurl = process.env.REACT_APP_API_URL_FOR_SEMANTIC;
  const [createnewfolderstatuslocal, setCreateNewFolderStatuslocal] = useState<Boolean>(false);
  const [uploadfilestatus, setNewFileStatus] = useState<Boolean>(false);
  const token = _localStorageService.getAccessToken();
  const [selectedFolderTable, setselectedFolderTable] = useState<Folder>(folderData ? folderData[0] : []);
  const [pathName, setPathName] = useState<any[]>([folderData ? folderData[0] : []]);
  const [dataSource, setDataSource] = useState<any[]>([...(selectedFolderTable?.folders || []), ...(selectedFolderTable?.document || [])]);
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filename, setFileName] = useState<string>("");
  const [checkedFiles, setCheckedFiles] = useState<{ [key: string]: boolean }>({});
  const [selectedFolder, setselectedFolder] = useState<string>("");
  const [selectedFileNameForChat, setSelectedFileNameForChat] = useState("");
  console.log(componentUseCase)


  useEffect(() => {
    const fetchFolderData = async () => {
      try {
        setIsLoadingFolderData(true);
        const response = await getFileManagementDocuments();
        if (!response) {
          setIsLoadingFolderData(false);
          setIsModalVisible(false);
          return;
        }
        setFolderData(response?.data);
        setselectedFolderTable(response?.data[0]);
        setPathName([response?.data[0]]);
        setDataSource([...(response?.data[0]?.folders || []), ...(response?.data[0]?.document || [])]);
        setSelectedFileNameForChat(response?.data[0]?.document[0]?.filename || "");
        setselectedFolder(response?.data[0]?.name);
        setIsLoadingFolderData(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFolderData();
  }, []); // Empty dependency array means this effect runs once on mount

  const handlestateofselectedFolder = async (foldername: string, folder: Folder) => {
    setselectedFolder(foldername);
    setselectedFolderTable(folder);
    setPathName([folder]);
    handleDataSource(folder);
  
    // Ensure selected files remain across folder changes
    setSelectedFileNameForChat(folder?.document[0]?.filename || "");
  
    // Do not reset checkedFiles when changing folders
  };

  const handleDataSource = async (foldertobemapped: Folder) => {
    setDataSource([...foldertobemapped?.folders, ...foldertobemapped?.document]);
  };
  const handlestateofChildFolder = async (foldername: string, folder: Folder) => {
    setNewFileStatus(false);
    setselectedFolderTable(folder);
    setSelectedFileNameForChat(folder?.document[0]?.filename || "");
    setPathName([...pathName, folder]);
    handleDataSource(folder);
  };
  const handleToggleBetweenParentChildFolders = (folder: Folder) => {
    const index = pathName.findIndex((folderitem: Folder) => folderitem === folder);
    if (index !== -1) {
      const newPathName = pathName.slice(0, index + 1);
      setPathName(newPathName);
      setSelectedFileNameForChat(folder?.document[0]?.filename || "");
      setselectedFolderTable(folder);
      handleDataSource(folder);
    }
  };
  const handleSubmitFileForChatWithDoc = () => {
    const selectedFiles = Object.keys(checkedFiles).filter((filename) => checkedFiles[filename]);
    console.log(componentUseCase)
    if(activeModule===SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH && componentUseCase==="Chat With Document"){
    dispatch(setNotificationMsgAction("File has been selected for Chat with Document"))
    dispatch(FileForChatWithDoc(selectedFiles));
    dispatch(setSearchType(componentUseCase));
    }
    else if(activeModule===SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH && componentUseCase==="Summariser"){
      if(selectedFiles.length>=1)
      {
      dispatch(setNotificationMsgAction("File has been selected for using Summariser"))
      dispatch(FileForChatWithDoc(selectedFiles));
      dispatch(setSearchType(componentUseCase));
      }
    }
    else if(activeModule===SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS || (editReportGenerationScreen)){
      
     
      if(setLoadData){
        if (setIsArtifactFile) {
          setIsArtifactFile(selectedFileNameForChat);
          if(selectedFileNameForChat==="")
          {
          setLoadData({ isLoading: true,message:"common" });
          }
          else{
            setLoadData({ isLoading: true,message: loadData?.message!=="file"?"both":"file" });
          }
        } 
      }
      
    }
    setIsModalVisible(false);
    handleAdvancedSearchClose() 
  };

  const columns = [
    {
      title: `Name`,
      dataIndex: "filename", // This can remain 'filename' or be any key since we handle both in render
      key: "filename",
      render: (value: any, record: any, index: any) => {
        const displayValue = record.filename || record.name || "";
        return (
          <>
            {record.type === "folder" ? (
              <>
                <Tooltip title={displayValue}>
                  <span
                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer" }}
                    onClick={() => {
                      handlestateofChildFolder(record.name, record);
                    }}
                  >
                    <a style={{ color: "#034EA2" }}>{displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}</a>
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
              {activeModule!==SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS && (!editReportGenerationScreen)?(<>
             <input
                                type="checkbox"
                                checked={!!checkedFiles[record.filename]} // Set checkbox checked state
                                onChange={(e) => handleCheckboxChange(e.target.checked, record)}
                                style={{ color: 'blue', marginTop: '5px' }}
                            />
                            </>):(<></>)}
              <Tooltip title={displayValue}>
                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer" ,marginLeft:'10px'}}>
                  <a target="_blank" rel="noopener noreferrer" style={{ color: "#034EA2" }}>
                    {displayValue.length < 30 ? displayValue : displayValue.slice(0, 30) + "..."}
                  </a>
                </span>
              </Tooltip>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Uploaded by",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Uploaded on",
      dataIndex: "created_at",
      key: "created_at",
      render: (value: any, record: any, index: any) => (
        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer" }}>
          {/* {value?.includes(".pdf") ? value : value?.slice(0, 30) + "..." || " "} */}
          {value?.split("T")[0]}
        </span>
      ),
    },
  ];
  const handleCheckboxChange = (isChecked: boolean, record: any) => {
    setCheckedFiles((prev) => ({
      ...prev,
      [record.filename]: isChecked,
    }));
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(10px)", // Background blur effect
          background: "rgba(44, 78, 101, 0.5)", // semi-transparent background with the color #2C4E65
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 1s ease-in-out", // transition effect
          zIndex: 999999,
        }}
      >
        <div
          style={{
            position: "fixed",
            zIndex: 99999999999,
            top: 16,
            fontWeight: "600",
            fontSize: "30px",
            right: 30,
            cursor: "pointer",
          }}
          onClick={() => {
            if(setLoadData){
              setLoadData({ isLoading: true,message: loadData?.message!=="file"  ?"common":"file" });
            }
            setIsModalVisible(false);
            
          }}
        >
          X
        </div>
        {loadingData ? (
          <Spin />
        ) : (
          <>
            <Col span={6}>
              <Card
                style={{
                  width: "98%",
                  boxShadow: "0px 0px 10px #0000001F",
                  opacity: "1",
                  height: "530px",
                  marginRight:'10px'
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    padding: "18px",
                    fontSize: "16px",
                    color: "#1e4da1",
                    gap: "10px",
                    border: "none",
                    display: "flex",
                    backgroundColor: "F6FCFB",
                    borderRadius: "8px",
                    fontWeight: "900",
                    justifyContent: "center",
                    alignItems: "center",

                    cursor: "pointer",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>Folders</p>
                </div>
                <div style={{ overflowY: "auto", height: "400px" }}>
                  {folderData?.map((folder: any, index: number) =>
                    selectedFolder !== folder?.name ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: folder.source !== "Manual" ? "0px 6px" : "10px 6px", // Adjust padding based on folder.source
                          marginTop: folder.source !== "Manual" ? "0px" : "10px", // Adjust marginTop based on folder.source
                          fontWeight: "600",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handlestateofselectedFolder(folder?.name, folder);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "#1e4da1" }}>
                          <FolderOutlined style={{ color: "#1e4da1", fontSize: "20px", paddingLeft: "5px" }} />
                          <div style={{ display: "flex", flexDirection: "column", paddingTop: folder.source !== "Manual" ? "15px" : "0px" }}>
                            <span style={{}}>{folder?.name}</span>
                            {folder.source !== "Manual" && (
                              <span style={{ fontSize: "10px", fontStyle: "italic", color: "black" }}>via Sharepoint</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            background: "#1e4da1",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: folder.source !== "Manual" ? "0px 6px 6px 6px" : "10px 6px 10px 6px", // Adjust padding based on folder.source
                            marginTop: folder.source !== "Manual" ? "10px" : "10px", // Adjust marginTop based on folder.source
                            fontWeight: "600",
                            fontSize: "15px",
                            cursor: "pointer",
                            display: "flex",
                            borderRadius: "8px",
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "white", fontWeight: "600", fontSize: "15px" }}>
                            <FolderOutlined style={{ color: "white", fontSize: "20px", paddingLeft: "5px" }} />
                            <div style={{ display: "flex", flexDirection: "column", paddingTop: folder.source !== "Manual" ? "10px" : "0px" }}>
                              <span style={{}}>{folder?.name}</span>
                              {folder.source !== "Manual" && (
                                <span style={{ fontSize: "10px", fontStyle: "italic", fontWeight: "bold" }}>via Sharepoint</span>
                              )}
                            </div>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <EditOutlined style={{ color: "white", fontSize: "20px", paddingLeft: "5px", cursor: "not-allowed" }} />
                            <DeleteOutlined style={{ color: "white", fontSize: "20px", paddingLeft: "5px", cursor: "pointer" }} onClick={() => {}} />
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </Card>
            </Col>
            <Col span={16}>
              <Card style={{ width: "100%", boxShadow: "0px 0px 10px #0000001F", minHeight: "530px", opacity: "1" }}>
                {uploadfilestatus ? (
                  <>
                    {selectedFolderTable?.document?.length === 0 || createnewfolderstatuslocal ? (
                      <>
                        {" "}
                        <div style={{ paddingTop: createnewfolderstatuslocal ? "60px" : "40px" }}>
                          <Col span={24} className="d-flex justify-content-center align-items-center">
                            <Lottie id="email" animationData={PROJECT_ICONS.CREATE_FOLDERS} loop={true} style={{ height: "35vh", width: "35vw" }} />
                          </Col>
                          <Col
                            span={24}
                            className="d-flex justify-content-center align-items-center"
                            style={{ fontSize: "16px", color: "#231F20", fontWeight: "400" }}
                          >
                            No Documents Available
                          </Col>
                          <Col
                            span={24}
                            className="d-flex justify-content-center align-items-center"
                            style={{ fontSize: "16px", color: "#66676B", fontWeight: "400" }}
                          >
                            No Documents Available
                          </Col>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ height: "400px", overflowY: "auto" }}>
                          <Table dataSource={dataSource} pagination={false} />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <Row justify="space-between">
                      <Col style={{ display: "flex" }} xs={{ span: 12 }}>
                        <Paragraph style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "16px" }}>Folder Path : </Paragraph>
                        <p style={{ marginLeft: "5px", fontWeight: "bold", fontSize: "16px" }}>
                          {pathName &&
                            pathName.map((item, index) => (
                              <React.Fragment key={index}>
                                <a
                                  style={{ color: "#66676B", cursor: "pointer" }}
                                  onClick={() => {
                                    handleToggleBetweenParentChildFolders(item);
                                  }}
                                >
                                  {" "}
                                  {item?.name}
                                </a>
                                {index < pathName.length - 1 && <span style={{ marginLeft: "5px", marginRight: "5px" }}>{">"}</span>}
                              </React.Fragment>
                            ))}
                        </p>{" "}
                      </Col>
                      <Button style={{ backgroundColor: "#1e4da1", color: "white" }} onClick={handleSubmitFileForChatWithDoc}>
                        SUBMIT FILE
                      </Button>
                    </Row>
                    <div style={{ height: "420px", overflowY: "auto" }}>
                      <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        onRow={(record) => 
                          activeModule===SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS ? {
                            onClick: () => {
                              setSelectedFileNameForChat(record.filename);
                            },
                            style: {
                              backgroundColor: selectedFileNameForChat === record.filename ? "#B5D9EE" : "white",
                              cursor: "pointer",
                              pointerEvents: selectedFileNameForChat === record.filename ? "none" : "auto", // Disable hover effect on selected row
                            },
                          } : {}
                        }
                      />
                    </div>
                  </>
                )}
              </Card>
              {/* </Card> */}
            </Col>
          </>
        )}
      </div>
    </>
  );
};
