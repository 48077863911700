import { FC, useEffect, useState } from "react";
import { Card, Col, Row, Typography, Tooltip, Tag, Image, Button, Input } from "antd";
import { ContainerOutlined, DownOutlined, EditOutlined, DownloadOutlined, ExpandAltOutlined, TableOutlined, SearchOutlined } from "@ant-design/icons";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import Markdown from "markdown-to-jsx";
import { ModalForEditingArtifactContent } from "../_ModalForEditingArtifactContent";
import { AnyMxRecord } from "dns";
import { useSelector } from "react-redux";

interface GeneralArtifactsProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleAdvancedSearchClose: () => void;
  artifacts: any;
  setIsArtifacts: (value: any) => void;
  fileSpecificArtefact: any[];
  setFileSpecificArtefact: (value: any[]) => void;
  commonArtefacts: any[];
  setCommonArtefacts: (value: any[]) => void;
  switchForArtefact: number;
  setSwitchForArtefacts: (value: number) => void;
  setLoadData?: React.Dispatch<React.SetStateAction<LoadDataType>>;
  stepperForReport?: number;
  setStepperForReport?: React.Dispatch<React.SetStateAction<number>>;
  artifactsForReport?: any[]; // Add artifactsForReport prop
  setArtifactsForReport?: React.Dispatch<React.SetStateAction<any[]>>;
}
interface LoadDataType {
  isLoading: boolean;
  message: string;
}

export const CommonArtefactsWrapper: React.FC<GeneralArtifactsProps> = ({
  setIsModalVisible,
  handleAdvancedSearchClose,
  artifacts,
  setIsArtifacts,
  fileSpecificArtefact,
  setFileSpecificArtefact,
  commonArtefacts,
  setCommonArtefacts,
  switchForArtefact,
  setSwitchForArtefacts,
  setLoadData,
  setStepperForReport,
  stepperForReport,
  artifactsForReport,
  setArtifactsForReport

}) => {
   const {

      editReportGenerationScreen,
      
    } = useSelector((state: any) => state?.semanticSearchReducer);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedImageData, setSelectedImageData] = useState<any>([]);
  const [uniquelySelectedImageData, setUniquelySelectedImageData] = useState<any>({});
  const [selectedSummaryData, setSelectedSummaryData] = useState<any>([]);
  const [selectedKeywordsData, setSelectedKeywordsData] = useState<any>([]);
  const [uniquelySelectedSummaryData, setUniquelySelectedSummaryData] = useState<any>({});
  const [selectedSummaryComponent, setSelectedSummaryComponent] = useState("Summary");
  const [selectedDataTableData, setSelectedDataTableData] = useState<any>([]);
  const [uniquelySelectedTableData, setUniquelySelectedTableData] = useState<any>({});
  const [selectedCommonAnswerData, setSelectedCommonAnswerData] = useState<any>([]);
  const [uniquelySelectedCommonAnswerData, setUniquelySelectedCommonAnswerData] = useState<any>({});
  const [selectedType, setSelectedType] = useState<string>("Images");
  const [selectedCommonType, setSelectedCommonType] = useState<string>("Answers");
  const [isModalOpenForEditing, setIsModalOpenForEditing] = useState<boolean>(false);
  const [uniquelySelectedImageDataText, setUniquelySelectedImageDataText] = useState<any>("");
  const [uniquelySelectedImageDataDescription, setUniquelySelectedImageDataDescription] = useState<any>("");
  const [uniquelySelectedTableDataDescription, setUniquelySelectedTableDataDescription] = useState<any>("");

  const [selectedImageComponent, setSelectedImageComponent] = useState("Extracted Text");
  
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const [stepper,setStepper]=useState(1);

  console.log(selectedSummaryData);

  useEffect(() => {
    if (switchForArtefact === 1) {
      console.log("yes i get called ");
      fileSpecificArtefact.forEach((entry: any) => {
        switch (entry.key) {
          case "Data Table":
            setSelectedDataTableData(entry.item);
            setUniquelySelectedTableData(entry.item[0]);
            break;
          case "Image":
            setUniquelySelectedImageData(entry.item[0]);
            setSelectedImageData(entry.item);
            break;
          case "Summary":
            setSelectedSummaryData(entry.item);
            setUniquelySelectedSummaryData(entry.item[0]);
            break;
          default:
            break;
        }
      });
      commonArtefacts.forEach((entry: any) => {
        switch (entry.key) {
          case "Answer":
            setSelectedCommonAnswerData(entry.item);
            setUniquelySelectedCommonAnswerData(entry.item[0]);
            break;
          default:
            break;
        }
      });
    }
  }, [switchForArtefact]);


  useEffect(() => {
    // Check if uniquelySelectedCommonAnswerData and uniquelySelectedCommonAnswerData.data exist
    if (uniquelySelectedCommonAnswerData && uniquelySelectedCommonAnswerData.data) {
      // Regex to extract question and answer
      const questionMatch = uniquelySelectedCommonAnswerData.data.match(/Question:\s*(.+?)\n/);
      const answerMatch = uniquelySelectedCommonAnswerData.data.match(/Answer:\s*([\s\S]+?)(?=\n###|$)/); // Until the next heading or end of string
  
      // Set the state with extracted question and answer if matches are found
      if (questionMatch) {
        setQuestion(questionMatch[1].trim()); // Trim to remove extra whitespace
      }
      if (answerMatch) {
        setAnswer(answerMatch[1].trim()); // Trim to remove extra whitespace
      }
    } else {
      // Handle the case where data is undefined or missing
      console.warn('Missing or undefined uniquelySelectedCommonAnswerData');
      setQuestion('');
      setAnswer('');
    }
  }, [uniquelySelectedCommonAnswerData]);
  
  const openS3LinkInNewTab = (link:any) => {
    if (link) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      console.error("No valid link provided");
    }
  };
  

  

  const handleEditingContentOfArtifact = () => {
    setIsModalOpenForEditing(true);
  };
  const prefix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#1677ff",
      }}
    />
  );

  return (
    <>
      <Col span={5}>
        <Card
          style={{
            width: "100%",
            boxShadow: "0px 0px 10px #0000001F",
            opacity: "1",
            height: "530px",
            borderRadius: "10px",
            border: "1px solid #d9d9d9",
          }}
        >
        
          <div style={{ overflowY: "scroll", maxHeight: "420px" }}>
           
              
                <div
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: "#1E4DA1",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <img src={PROJECT_ICONS.ANSWERS} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Answers</p>
                </div>
                <div
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: "#EDF4FF",
                    color: "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.PERSONA} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Persona</p>
                </div>
                <div
                  style={{
                    height: "140px",
                    padding: "10px",
                    background: "#EDF4FF",
                    color: "#1E4DA1",
                    borderRadius: "5px",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "not-allowed",
                  }}
                >
                  <img src={PROJECT_ICONS.KNOWLEDGEGRAPH} style={{ borderRadius: "5px", marginTop: "5px" }} />
                  <p style={{ marginTop: "2px" }}>Knowledge Graph</p>
                </div>
              
           
          </div>
        </Card>
      </Col>

      <Col span={19}>
        <div
          style={{
            width: "100%",
            boxShadow: "0px 0px 10px #0000001F",
            height: "530px",
            opacity: "1",
            borderRadius: "10px",
            paddingTop: "0px",
            backgroundColor: "white",
            position: "relative", // Added this line to allow absolute positioning within this card
          }}
        >
          {/* Toggler placed here */}
          <div
            style={{
              display: "flex",
              borderRadius: "50px",
              overflow: "hidden",
              border: "2px solid #1e4da1",
              zIndex: "3",
              position: "absolute",
              top: "-10px", // Adjust to make it look centered over the top border
              left: "40%",
              transform: "translateX(-50%)", // Centers it horizontally
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "6px",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
                color: switchForArtefact == 2 ? "#ffffff" : "#1e4da1",
                backgroundColor: switchForArtefact == 2 ? "#1e4da1" : "#edf4ff",
              }}
              onClick={() => setSwitchForArtefacts(2)}
            >
              FILE ARTEFACT
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "6px",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
                color: switchForArtefact == 1 ? "#ffffff" : "#1e4da1",
                backgroundColor: switchForArtefact == 1 ? "#1e4da1" : "#edf4ff",
              }}
              onClick={() => setSwitchForArtefacts(1)}
            >
              COMMON ARTEFACT
            </div>
          </div>
          <Row>
            { selectedCommonType==="Answers"&&stepper==1?(<>
                <Col span={24}>
              <div
                style={{
                  background: "white",
                  height: "530px",
                  marginLeft: "50px",
                  marginRight: "20px",
                  overflow: "auto",
                  paddingBottom: "10px",
                  marginBottom: "15px",
                  paddingTop: "20px",
                }}
              >
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{flex:'2'}}>
                        <div style={{fontWeight:'bold'}}>Answers ({selectedCommonAnswerData.length}) </div>
                        <div>Tip:Click on the answer to view</div>
                    </div>
                    {editReportGenerationScreen?(<>

<Button style={{background:'#1E4DA1',color:'white',marginRight:'10px'}} onClick={() => {
      if (setStepperForReport) {
        setStepperForReport(2);
      } else {
        console.warn("Stepper is not defined.");
      }
    }}>Proceed</Button>
</>):
                   ( <div style={{flex:'1'}}>
                   
                <Col  style={{ width: "100%" }}>
                  <Col xs={{ span: 24 }}>
                   
                  <>
                    <Input
                      prefix={prefix}
                      placeholder="Search By Answer , Keyword"
                    />
                    </>
                  </Col>
              </Col>
                    </div>)}

                </div>



                <div style={{ marginTop: '20px', height: '400px', overflowY: 'scroll', overflowX: 'hidden' }}>
    {selectedCommonAnswerData.length === 0 ? (
        <div style={{ color: '#5F5F5F', fontSize: '14px',display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
            No common answers available.
        </div>
    ) : (
        <Row gutter={[16, 16]} wrap={true} style={{ display: 'flex', gap: '30px' }}>
            {selectedCommonAnswerData.map((item: any, index: any) => (
                <Col 
                    key={index} 
                    span={5} 
                    style={{ borderRadius: '5px', border: '1px solid #DBDBDB', padding: '10px', marginLeft: '10px', cursor: 'pointer' }} 
                >
 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div 
                style={{ color: '#1E4DA1', fontSize: '14px', fontWeight: 'bold' }}
                onClick={() => { 
                    setUniquelySelectedCommonAnswerData(item); 
                    setStepper(2); 
                }}
            >
                {item?.title}
            </div>
            {editReportGenerationScreen?(

            <>
            <input 
                type="checkbox" 
                style={{ marginRight: '10px' }} 
                onChange={(e) => {
                  if (e.target.checked) {
                      // Ensure setArtifactsForReport is callable
                      if (setArtifactsForReport) {
                          setArtifactsForReport((prev: any[] = []) => [...prev, item.id]);
                      }
                  } else {
                      if (setArtifactsForReport) {
                          setArtifactsForReport((prev: any[] = []) => 
                              prev.filter((artifact: any) => artifact !== item.id)
                          );
                      }
                  }
              }} 
            /></>):(<></>)}
        </div>                    
                   
                    <div style={{ color: '#5F5F5F', fontSize: '12px', marginTop: '10px' }}                     onClick={() => { setUniquelySelectedCommonAnswerData(item); setStepper(2); }}
                    >
                        {(() => {
                            const visibleLines = Math.floor(window.innerHeight / 4);

                            return (
                                <>
                                    {item.data.slice(0, visibleLines)}
                                    {item.data.length > visibleLines && (
                                        <span style={{ color: '#1E4DA1', fontWeight: 'bold', marginLeft: '2px' }}>
                                            View More...
                                        </span>
                                    )}
                                </>
                            );
                        })()}
                    </div>
                </Col>
            ))}
        </Row>
    )}
</div>


</div>
</Col>
        
            </>):(
                <>
            <Col span={18}>
              <div
                style={{
                  background: "#EDF4FF",
                  height: "530px",
                  marginLeft: "50px",
                  marginRight: "20px",
                  overflow: "auto",
                  paddingBottom: "10px",
                  marginBottom: "15px",
                  paddingTop: "20px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      background: "white",
                      display: "flex",
                      zIndex: "2",
                      position: "absolute",
                      marginRight: "0",
                      marginTop: "3px",
                      gap: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  >
                    
                    <div style={{ borderRight: "1px solid black", paddingRight: "5px", cursor: "not-allowed" }}>
                      <DownloadOutlined style={{ height: "20px" }} />
                    </div>
                    <div>
                      <ExpandAltOutlined style={{ height: "20px", paddingRight: "5px", cursor: "not-allowed" }} />
                    </div>
                  </div>
                </div>
                {selectedCommonAnswerData.length > 0 ? (
                    <>
                    
                      <div style={{ padding: "20px" }}>
                      <Button type="link" style={{fontWeight:'bold',color:'#1E4DA1',fontSize:'16px',cursor:'pointer'}} onClick={()=>{setStepper(1);setUniquelySelectedCommonAnswerData({})}}>BACK TO ANSWERS</Button>
                        <div
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                            // Allows long words to be broken and wrap to the next line
                            wordBreak: "break-all",
                          }}
                        >
                          <div style={{fontWeight:'bold',                            fontSize:'16px'
}}
                          >
                          <Markdown>{question}</Markdown>
                          </div>
                          <div style={{marginTop:'10px'}}>
                          <Markdown>{answer}</Markdown>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Answer Data found.</div>
                  )}
               
                 
             
              </div>
            </Col>
            <Col span={6}>
            <div style={{fontWeight:'bold',marginTop:'40px',fontSize:'18px'}}>Sources</div>
            <div style={{overflowY:'auto',height:'400px'}}>
            {uniquelySelectedCommonAnswerData.artifact_file && uniquelySelectedCommonAnswerData.artifact_file.length > 0 ? (
  uniquelySelectedCommonAnswerData.artifact_file.map((artifact:any, index:any) => {
    const name = artifact.filename || "Unknown Name"; // Handle undefined or missing name
    const link = artifact.s3_url || "#"; // Handle undefined or missing link

    return (
      <Col span={20} key={index} style={{marginTop:'10px'}} >
        <Tooltip title={name}>
          <div style={{ position: "relative", paddingTop: "10px", cursor: "pointer" }}>
            <div
              style={{
                padding: "10px",
                backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                backgroundSize: "cover",
                borderRadius: "15px",
                height:'80px'
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  marginRight: "10px",
                  color: "#66676B",
                }}
                onClick={() => openS3LinkInNewTab(link)} // Un-comment this when you want the click functionality
              >
                {name.slice(0,60)}...
              </p>
            </div>
          </div>
        </Tooltip>
      </Col>
    );
  })
) : (
  <p>No sources available</p> // Fallback message when artifact_file is empty or undefined
)}
</div>
            </Col>
            
            </>)}
           
          </Row>
        </div>
      </Col>

      {isModalOpenForEditing && selectedType === "Images" ? (
        <ModalForEditingArtifactContent
          isModalOpenForEditing={isModalOpenForEditing}
          data={uniquelySelectedImageData}
          setData={setUniquelySelectedImageData}
          setIsModalOpenForEditing={setIsModalOpenForEditing}
          selectedTypeData={selectedImageData} // Add this line
          setSelectedData={setSelectedImageData} // Add this line
          setLoadData={setLoadData}
          switchForArtefact={switchForArtefact}
          setCommonArtefacts={setCommonArtefacts}
          commonArtefacts={commonArtefacts}
          setFileSpecificArtefact={setFileSpecificArtefact}
          fileSpecificArtefact={fileSpecificArtefact}
        />
      ) : (
        <></>
      )}
      {isModalOpenForEditing && selectedType === "Summary" ? (
        <ModalForEditingArtifactContent
          isModalOpenForEditing={isModalOpenForEditing}
          data={uniquelySelectedSummaryData}
          setData={setUniquelySelectedSummaryData}
          setIsModalOpenForEditing={setIsModalOpenForEditing}
          selectedTypeData={selectedSummaryData} // Add this line
          setSelectedData={setSelectedSummaryData} // Add this line
          setLoadData={setLoadData}
          switchForArtefact={switchForArtefact}
          setCommonArtefacts={setCommonArtefacts}
          commonArtefacts={commonArtefacts}
          setFileSpecificArtefact={setFileSpecificArtefact}
          fileSpecificArtefact={fileSpecificArtefact}
        />
      ) : (
        <></>
      )}
      {isModalOpenForEditing && selectedType === "Table" ? (
        <>
          <ModalForEditingArtifactContent
            isModalOpenForEditing={isModalOpenForEditing}
            data={uniquelySelectedTableData}
            setData={setUniquelySelectedTableData}
            setIsModalOpenForEditing={setIsModalOpenForEditing}
            selectedTypeData={selectedDataTableData} // Add this line
            setSelectedData={setSelectedDataTableData} // Add this line
            setLoadData={setLoadData}
            switchForArtefact={switchForArtefact}
            setCommonArtefacts={setCommonArtefacts}
            commonArtefacts={commonArtefacts}
            setFileSpecificArtefact={setFileSpecificArtefact}
            fileSpecificArtefact={fileSpecificArtefact}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};






// {selectedCommonAnswerData.length > 0 ? (
//   <>
//     <div style={{ padding: "20px" }}>
//       <div
//         style={{
//           padding: "10px",
//           marginTop: "10px",
//           whiteSpace: "pre-wrap",
//           overflowWrap: "break-word",
//           // Allows long words to be broken and wrap to the next line
//           wordBreak: "break-all",
//         }}
//       >
//         <Markdown>{uniquelySelectedCommonAnswerData?.data}</Markdown>
//       </div>
//     </div>
//   </>
// ) : (
//   <div style={{ textAlign: "center", marginTop: "20px", color: "#848484" }}>No Answer Data found.</div>
// )}
// </>):(<></>)