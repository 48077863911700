import { useEffect, useState } from "react";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  getFinalAssetURL,
  saveFinalAssetData,
  sseGetFinalAsset,
  sseGetAssetBanner,
  saveAssestsBanner
} from "../../../../Store/requests/_contentNewtonRequests";
import { FinalAssetSSEResponse } from "../Module Selection/types";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import { setCNCurrentIndexDoneNextIndexProcess, setCNFinalAsset } from "../../../../Store/actions/_contentNewtonActions";
import { Button, notification, Skeleton, Tooltip, Col, Row } from "antd";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { DownloadOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { FinalAssetPreviewModal } from "./FinalAssetPreviewModal";
import { FinalAsset as AssetInfoType } from "../../resources/types";
import { BannerEditData } from "../Localisation/Modals/AssembleModal";
import { BannerEditModal } from "../Localisation/Modals/EditBannerModal/EditBannerModal";
import { capitalizeFirstLetter } from "../../../../Utils/_gFunctions/helpers";

export const FinalAsset = () => {
  const { currentStep, finalAssets, orderId, selectedTemplateId, modulesExtraInfo }: ContentNewtonState = useAppSelector(
    (state) => state.contentNewtonReducer
  );

  const [loading, setLoading] = useState(finalAssets.length === 0);
  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [visible, setVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<AssetInfoType>();

  const [channel, setChannels] = useState<string>(localStorage.getItem("contentNewtonChannel") || "email");
  const [bannerEditData, setBannerEditData] = useState<BannerEditData>();
  const [bannerLoader, setBannerLoader] = useState(false);
  const [openEditModalBanner, setOpenEditModalBanner] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (finalAssets && finalAssets.length > 0) {
      return;
    }

    const sse = sseGetFinalAsset(orderId, selectedTemplateId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: FinalAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: FinalAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
        } else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          dispatch(setCNFinalAsset(parsedData.data));
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Final Assets Listing Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [finalAssets]);

  const isFinalAssetStep = ConversationStepsMapping[currentStep] === ConversationStepsName.CREATING_FINAL_ASSET;

  const handleEdit = (assetInfo: AssetInfoType) => {
    setSelectedAsset(assetInfo);
    if (channel === "email") {
      setEditMode(true);
      setOpenPreviewModal(true);
    } else {
      console.log("assetInfo", assetInfo);
      fetchEditBannerData(assetInfo);
    }
  };

  //   const handleDownload = async () => {
  //     if (!finalAsset) return;
  //     const res = await getFinalAssetURL(finalAsset.asset_id);

  //     if (res?.data?.url) {
  //       const element = document.createElement("a");
  //       element.href = res.data.url;
  //       document.body.appendChild(element);
  //       element.click();
  //       document.body.removeChild(element);
  //     }
  //   };
  const handleDownload = async (html?: string) => {
    if (!html) return;
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(html));
    element.setAttribute("download", `${finalAssetFileName}.html`);
    element.click();
  };

  const fetchEditBannerData = async (assetInfo: AssetInfoType) => {
    setBannerLoader(true);
    let sse = sseGetAssetBanner(assetInfo.asset_id);
    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setBannerLoader(false);

      let Data: any;
      try {
        Data = JSON.parse(data.data);
        console.log("Data101", Data);
        if (!Data?.data) {
          notification.error({ message: "Error parsing banner data" });
          return;
        }
        setBannerEditData(Data.data);
        setOpenEditModalBanner(true);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        notification.error({ message: "Error parsing banner data" });
        // sse.close();
        return;
      }
    });

    sse.onerror = (err: any) => {
      notification.error({ message: `SSE Error While Getting Data!\n ${err}` });
      sse.close();
      setBannerLoader(false);
    };
  };

  const handlePreview = (assetInfo: AssetInfoType) => {
    setEditMode(false);
    setOpenPreviewModal(true);
    setSelectedAsset(assetInfo);
  };

  const finalAssetFileName = modulesExtraInfo?.brand_name
    ? `${capitalizeFirstLetter(modulesExtraInfo?.brand_name?.toLowerCase())}_English`
    : "FinalAsset";

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          <Row
            gutter={[8, 8]}
            style={{
              display: "flex",
              overflowX: "auto",
              padding: "5px",
              maxWidth: "100%", // Ensure it doesn't exceed the parent width
              WebkitOverflowScrolling: "touch" // Smooth scrolling on iOS
            }}
          >
            {finalAssets.length > 0 &&
              finalAssets.map((assetInfo: AssetInfoType, index: number) => {
                return (
                  <Col span={6} key={index}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                      <div
                        style={{
                          // width: "300px",
                          height: "300px",
                          backgroundColor: "#f0f7ff", //"white",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          border: "1px solid lightgray",
                          borderRadius: "10px",
                          overflow: "hidden"
                        }}
                        onMouseEnter={() => setVisible(true)}
                        onMouseLeave={() => setVisible(false)}
                      >
                        <img src={assetInfo?.thumbnail} style={{ width: "100%" }} />
                        <div
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            display: "flex",
                            gap: "5px",
                            visibility: visible ? "visible" : "hidden"
                          }}
                        >
                          <Tooltip title="Edit Asset">
                            <EditOutlined
                              style={{
                                display: isFinalAssetStep ? "block" : "none",
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "3px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(assetInfo);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Download">
                            <DownloadOutlined
                              style={{
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "3px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownload(assetInfo?.html);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Preview">
                            <EyeOutlined
                              style={{
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "3px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePreview(assetInfo);
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

          <div
            style={{
              display: isFinalAssetStep && finalAssets.length ? "flex" : "none",
              justifyContent: "flex-end",
              marginTop: "5px"
            }}
          >
            <Button type="primary" onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>
          {finalAssets.length > 0 && selectedAsset && (
            <FinalAssetPreviewModal
              open={openPreviewModal}
              setOpen={setOpenPreviewModal}
              asset={selectedAsset}
              edit={editMode}
              updateAsset={(updatedContent: string) => {
                const updatedAssets = finalAssets.map((asset: AssetInfoType) => {
                  if (asset.asset_id === selectedAsset.asset_id) {
                    return { ...asset, html: updatedContent } as AssetInfoType;
                  }
                  return asset;
                });
                dispatch(setCNFinalAsset(updatedAssets));
                setSelectedAsset((prevAssetInfo) => ({ ...prevAssetInfo, html: updatedContent } as AssetInfoType));
              }}
              updateAssetAPI={saveFinalAssetData}
              fileName={finalAssetFileName}
              disableEdit={!isFinalAssetStep}
            />
          )}
          {finalAssets.length > 0 && selectedAsset && bannerEditData && (
            <BannerEditModal
              open={openEditModalBanner}
              saveBannerApi={saveAssestsBanner}
              setOpen={setOpenEditModalBanner}
              setAsset={(updatedContent: string, id: number) => {
                const updatedAssets = finalAssets.map((asset: AssetInfoType) => {
                  if (asset.asset_id === id) {
                    return { ...asset, html: updatedContent } as AssetInfoType;
                  }
                  return asset;
                });
                dispatch(setCNFinalAsset(updatedAssets));
                setSelectedAsset((prevAssetInfo) => ({ ...prevAssetInfo, html: updatedContent } as AssetInfoType));
              }}
              editData={bannerEditData}
              id={selectedAsset.asset_id}
            />
          )}
        </div>
      )}
    </div>
  );
};
