import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { ModuleType } from "../types";
import { ModulePreviewTile } from "./ModulePreviewTile";
import { ModulePreview } from "./ModulePreview/ModulePreview";
import "./ModulesPreviewModal.css";
import { ContentNewtonState } from "../../../../../Store/reducers/_contentNewtonReducer";
import { useAppSelector } from "../../../../../Store/hooks";
import { isBrandMatch } from "../../../resources/helpers";

export const ModulesPreviewModal: React.FC<{
  open: boolean;
  setOpen: Function;
  previeweditem: ModuleType;
  modulesList: ModuleType[];
}> = ({ open, setOpen, previeweditem, modulesList }) => {
  const { modulesExtraInfo }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);
  const [selectedItem, setSelectedItem] = useState<ModuleType>(previeweditem);

  const oncancel = () => {
    setOpen(false);
  };
  const onOk = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (previeweditem) {
      setSelectedItem(previeweditem);
    }
  }, [previeweditem]);

  const claimsData = [
    { id: "CL-0001", description: "Almost one third of patients treated with OPDIVO® + chemo achieved a pathological complete response" }
    // Add more claims as needed
  ];

  const nonClaimsData = [
    { description: "PCR rate in PD-L1 ≥1 % patients" },
    { description: "PCR by stage of disease at baseline" }
    // Add more non-claims as needed
  ];

  const imagesData = [
    "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-169994-674010.jpg&fm=jpg", // 400px width
    "https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg", // 300px width
    "https://miro.medium.com/v2/resize:fit:1024/1*w7fSHgKimriL-ryZJ52Uig.png", // 500px width
    "https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg", // 250px width
    "https://www.shutterstock.com/search/big-images" // 350px width
  ];

  // modulesList.flatMap((item, index) =>
  //   Array.from({ length: 20 + 1 }).map((_, i) => ({
  //     ...item,
  //     id: `${item.module_id}-${i}`
  //   }))
  // );

  return (
    <Modal
      open={open}
      className="cn-fullscreen-variable-width-modal cn-modules-viewer"
      title={`${isBrandMatch(modulesExtraInfo, "camzyos") ? "Claims" : "Modules"} Preview`}
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ flex: 1, height: "calc(100vh - 160px)", overflowY: "auto" }}>
          {/* <div>Module: M-101</div>
          <div></div>
          <div>
            <div>
              <div>Claims</div>
              <div>Non-Claims</div>
            </div>
            <div>Images</div>
          </div> */}
          {/* <ModulePreview claims={claimsData} nonClaims={nonClaimsData} images={imagesData} /> */}
          <ModulePreview module={selectedItem} />
        </div>
        <div
          style={{
            height: "calc(100vh - 160px)",
            overflowY: "auto",
            width: "200px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            padding: "5px"
          }}
        >
          {modulesList?.map((item) => (
            <ModulePreviewTile item={item} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
          ))}
        </div>
      </div>
    </Modal>
  );
};
