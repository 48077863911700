import { Button, Card, Col, Image, Modal, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import TextArea from "antd/es/input/TextArea";
import { exportArtefactReport, getArtifactById, UpdateArtifactData } from "../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { LeftCircleOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

export interface ModalForEditingArtifactContentProps {
  isModalOpenForEditing: boolean;
  data: any;
  setIsModalOpenForEditing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTypeData: any[];
  setSelectedData: React.Dispatch<React.SetStateAction<any[]>>;
  setLoadData?:React.Dispatch<React.SetStateAction<LoadDataType>>;
  switchForArtefact:number;
  setData:React.Dispatch<React.SetStateAction<any>>;
  setCommonArtefacts: (value: any[]) => void;
  commonArtefacts: any[];
  setFileSpecificArtefact: (value: any[]) => void;
  fileSpecificArtefact:any[];

}
interface LoadDataType {
  isLoading: boolean;
  message: string;
}
interface TemplateSelectionProps {
    stepper?: number;
    setStepper?: React.Dispatch<React.SetStateAction<number>>;
    templateID?:string;
    setTemplateID?:React.Dispatch<React.SetStateAction<string>>;
  }


export const TemplateSelectionScreen: FC<TemplateSelectionProps> = ({stepper,setStepper,templateID,setTemplateID}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [items, setItems] = useState<string[]>([""]);
  const [selectedFormat, setSelectedFormat] = useState("Export as PPT");
  const [numColumns, setNumColumns] = useState<number>(0);
  const [numRows, setNumRows] = useState<number>(5);
  const [generatedSchema, setGeneratedSchema] = useState<boolean>(false);

  const [selectedCardId, setSelectedCardId] = useState<string>(templateID || "39c177bd-a567-419b-a5e8-bdb2fed48145");
  const [selectedCardPdfId,setSelectedCardPdfId]=useState<number|null>(0);
  const [imageWidth, setImageWidth] = useState(910); // Initial width is set to 910
  const [imageHeight , setImageHeight] = useState(450)


const handleCardSelection = (cardId: string) => {
  setSelectedCardId(cardId);
};
const handleCardPDFSelection = (cardId:number) =>{
  setSelectedCardPdfId(cardId)
}


  const PPTData = [
    [
      { id: 1, card: "main", backgroundImage: PROJECT_ICONS.PPTMAIN,templateID:"39c177bd-a567-419b-a5e8-bdb2fed48145" },
      { id: 2, card: "1", backgroundImage: PROJECT_ICONS.PPT1,templateID:""},
      { id: 3, card: "2", backgroundImage: PROJECT_ICONS.PDFMAIN,templateID:"2294f615-2a3c-4145-9130-1fdff250b87c" },
    ],
    [
    
      { id: 4, card: "3", backgroundImage: PROJECT_ICONS.PPT3,templateID:"" },
      { id: 5, card: "4", backgroundImage: PROJECT_ICONS.PPT4,templateID:"" },
      { id: 6, card: "5", backgroundImage: PROJECT_ICONS.PPT5 ,templateID:""},
    ]
  ];

  return (
 <>
 <Col span={24}>
    <Card className="card-element">
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <div style={{fontWeight:'700',color:'#1E4DA1',marginLeft:'17px',fontSize:'18px'}}>
            SELECT TEMPLATE
        </div>
        <div>
        <LeftCircleOutlined/>
        <span style={{marginLeft:'10px',fontWeight:'bolder'}}onClick={() => {
      if (setStepper) {
        setStepper(1);
      } else {
        console.warn("Stepper is not defined.");
      }
    }}>GO BACK</span>
        </div>
      </div>
      <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
                            {PPTData.map((row, rowIndex) => (
                              <Row key={rowIndex} gutter={16}>
                                {row.map((card: any, colIndex: any) => (
                                  <Col key={colIndex} span={8}>
                                    {/* <Card
                                      style={{
                                        height: "200px",
                                        backgroundImage: `url(${card.backgroundImage})`,
                                        backgroundSize: "cover", // This will make the image cover the entire card
                                        backgroundPosition: "center", // Optional: centers the image
                                        backgroundRepeat: "no-repeat", // Optional: prevents repeating the image
                                        border: card.id === selectedCardId ? "4px solid #034EA2" : "1px solid black", // Corrected condition
                                        cursor:'pointer'
                                      
                                      }}
                                      onClick={()=>{handleCardSelection(card.id)}}
                                    ></Card> */}
                                    <div style={{ height: "200px",border: card.templateID === selectedCardId ? "4px solid #034EA2" : "1px solid black", justifyContent:'center',background:'#F3F8FF',
                                        cursor:'pointer',borderRadius:'5px',padding:'15px'}}>
                                            <div style={{display:'flex',justifyContent:'center'}}>
                                     <Image style={{height:'140px',zIndex:9999999999999999999999999,width:'100%'}} src={card.backgroundImage}
                                      preview={{
                                        getContainer: () => document.body, // This ensures the preview modal renders at the body level
                                      }}
                                     />
                                     </div>
                                     <div
  style={{
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: card.templateID === selectedCardId ? '0px' : '5px',
  }}
>
  <div>
    <img src={PROJECT_ICONS.PDF} style={{height:'16px',margin:'10px'}}></img>
    <img src={PROJECT_ICONS.PPT} style={{height:'16px',marginTop:'10px',marginBottom:'10px'}}></img>
  </div>
  <Button
    type="link"
    style={{
      fontWeight: 'bold',
      color: card.templateID === selectedCardId ? 'grey' : '#034EA2',
      cursor: card.id === 1 || card.id === 3 ? 'pointer' : 'not-allowed',
    }}
    onClick={() => {
      if (card.id === 1 || card.id === 3) {
        handleCardSelection(card.templateID);
        if(setTemplateID)
        {
        setTemplateID(card.templateID);
        }
      }
    }}
  >
    {card.templateID === selectedCardId ? 'Selected' : 'Select'}
  </Button>
</div>

                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ))}
                          </div>
                          <div style={{display:'flex',justifyContent:'flex-end'}}>
<Button style={{background:'#1E4DA1',color:'white'}} onClick={() => {
      if (setStepper) {
        setStepper(3);
      } else {
        console.warn("Stepper is not defined.");
      }
    }}>Proceed</Button>
                          </div>
    </Card>
    </Col>
 </>
  );
};
