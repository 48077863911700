import React, { FC, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Menu,
  Row,
  Typography,
  Spin,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  scheduleInfogramReport,
} from "../../../../Store/requests/_semanticSearchRequests";
import { setEditableScreen } from "../../../../Store/actions/_semanticSearchActions";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";

const { Paragraph } = Typography;

interface TemplateGenerationProps {
  stepper?: number;
  setStepper?: React.Dispatch<React.SetStateAction<number>>;
  webUrl?: string;
  setWebUrl?: React.Dispatch<React.SetStateAction<string>>;
  templateID: string;
}

export const TemplateGeneratedText: FC<TemplateGenerationProps> = ({
  webUrl,
  setStepper,
  setWebUrl,
  templateID,
}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();

  const [selectedFormat, setSelectedFormat] = useState<string>("Select Format");
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const formatImages: Record<string, string> = {
    pdf: PROJECT_ICONS.PDF,
    png: "/path/to/png-image.png",
    ppt: PROJECT_ICONS.PPT,
  };

  const formatMenu = (
    <Menu
      onClick={({ key }) => {
        setSelectedFormat(`${key.toUpperCase()}`);
        setSelectedImage(formatImages[key]);
      }}
      items={[
        {
          key: "pdf",
          label: "PDF",
        },
        {
          key: "png",
          label: "PNG",
        },
        {
          key: "ppt",
          label: "PPT",
        },
      ]}
    />
  );

  const handleReportDownload = async () => {
    setIsDownloading(true);
    try {
      const response = await scheduleInfogramReport({
        project_id: templateID,
        format: selectedFormat?.toLowerCase(),
      });

      if (!response.error) {
        console.log(response)
        const downloadUrl = response.data.completed_progress.result.url;

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = ""; // Optional: Specify a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(setEditableScreen(false));
        dispatch(setNotificationMsgAction("Report was downloaded successfully"));
      } else {
        console.error("Error in response:", response.error);
      }
    } catch (error) {
      dispatch(setNotificationMsgAction("An error occurred while downloading the report . Please try again"));
      console.error("An error occurred while downloading the report:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Col span={7}>
        <Card className="card-element">
          <div style={{ fontWeight: "700", fontSize: "18px" }}>Report Data</div>
        </Card>
      </Col>
      <Col span={17}>
        <div
          className="card-element"
          style={{ background: "white", padding: "20px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontWeight: "700", fontSize: "18px" }}>
              Report Preview
            </div>
            <div>
              <span
                style={{
                  color: "#1E4DA1",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (setWebUrl) {
                    setWebUrl("");
                  }
                  if (setStepper) {
                    setStepper(3);
                  }
                }}
              >
                Go Back
              </span>
            </div>
          </div>
          <iframe
            src={webUrl}
            style={{
              height: "85%",
              width: "100%",
              overflowX: "hidden",
              marginTop: "10px",
            }}
          ></iframe>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <span
              style={{
                color: "#1E4DA1",
                fontSize: "15px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setEditableScreen(false));
              }}
            >
              Cancel process
            </span>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Dropdown overlay={formatMenu} placement="bottomLeft">
                <Button>
                  {selectedImage && (
                    <Image
                      src={selectedImage}
                      preview={false}
                      style={{ width: 20, marginRight: 8 }}
                    />
                  )}
                  {selectedFormat}
                </Button>
              </Dropdown>
              <Button
                style={{
                  background: "#1E4DA1",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (selectedFormat !== "") {
                    handleReportDownload();
                  }
                }}
                disabled={isDownloading}
                loading={isDownloading}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};
