import React, { useState } from "react";
import { Layout, Menu, Button, Tabs, Card, List, Typography, Space, Image } from "antd";
import { BulbOutlined, ExperimentOutlined, RiseOutlined, ReadOutlined, BarChartOutlined } from "@ant-design/icons";
import { setUpdateLandingAgentSelection } from "../../../../Store/actions/_semanticSearchActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";

const { Header, Content } = Layout;
const { Title, Text, Paragraph } = Typography;

export const LandingScreenForAgent: React.FC = () => {
  const agents = [
    {
      name: "Brand IQ Agent",
      icon: <BulbOutlined />,
      details: {
        title: "Brand IQ Agent Features",
        tagline: "Your brand team's favourite helper",
        description: [
          "Create plans and creative briefs for your teams within minutes",
          "Create personalized messaging for target segments",
          "Mimic brand voice to ensure consistency",
        ],
        image: PROJECT_ICONS.BIQAGENT,
        color: "#3B82F6",
        features: [
          {
            title: "Multi-modal search",
            description: "Leverage latest multi-modal LLMs to provide best search results across text, images, audio, video",
          },
          {
            title: "Personalised reports",
            description: "Generate final output in templated reports for business consumption",
          },
          {
            title: "Pharma expertise",
            description: "Contextualises your data with pharma nuances",
          },
        ],
      },
    },
    {
      name: "Medical Research Agent",
      icon: <ExperimentOutlined />,
      details: {
        title: "Medical Research Agent Features",
        tagline: "Accelerate your medical research insights",
        description: [
          "Analyze and summarize complex medical literature",
          "Identify key trends and breakthroughs in specific medical fields",
          "Generate comprehensive research reports",
        ],
        image: PROJECT_ICONS.MAAGENT,
        color: "#14B8A6",
        features: [
          {
            title: "Advanced literature analysis",
            description: "Utilize NLP to extract key insights from vast medical databases and journals",
          },
          {
            title: "Clinical trial insights",
            description: "Summarize and analyze clinical trial data for faster decision-making",
          },
          {
            title: "Customized research focus",
            description: "Tailor the agent's expertise to your specific medical research area",
          },
        ],
      },
    },
    {
      name: "Competitive Intelligence Agent",
      icon: <RiseOutlined />,
      details: {
        title: "Competitive Intelligence Agent Features",
        tagline: "Stay ahead of the market curve",
        description: [
          "Monitor and analyze competitor activities and strategies",
          "Identify market trends and potential opportunities",
          "Generate actionable insights for strategic decision-making",
        ],
        image: PROJECT_ICONS.CIAGENT,
        color: "#EAB308",
        features: [
          {
            title: "Real-time market monitoring",
            description: "Continuously scan and analyze market data from multiple sources",
          },
          {
            title: "Competitor strategy analysis",
            description: "Decode competitor moves and predict potential future actions",
          },
          {
            title: "Opportunity identification",
            description: "Highlight potential market gaps and growth opportunities",
          },
        ],
      },
    },
    {
      name: "Literature Search Agent",
      icon: <ReadOutlined />,
      details: {
        title: "Literature Search Agent Features",
        tagline: "Your personal research assistant",
        description: [
          "Conduct comprehensive literature searches across multiple databases",
          "Summarize key findings and identify relevant studies",
          "Generate bibliographies and citation reports",
        ],
        image: PROJECT_ICONS.LRAGENT,
        color: "#D946EF",
        features: [
          {
            title: "Multi-database search",
            description: "Access and analyze content from various academic and scientific databases",
          },
          {
            title: "Smart summarization",
            description: "Provide concise summaries of complex research papers and studies",
          },
          {
            title: "Citation management",
            description: "Automatically generate and format citations in various styles",
          },
        ],
      },
    },
    {
      name: "Data Analysis Agent",
      icon: <BarChartOutlined />,
      details: {
        title: "Data Analysis Agent Features",
        tagline: "Transform raw data into actionable insights",
        description: [
          "Process and analyze large datasets quickly and accurately",
          "Generate visual representations of data for easy interpretation",
          "Provide predictive analytics and trend forecasting",
        ],
        image: PROJECT_ICONS.DAAGENT,
        color: "#F97316",
        features: [
          {
            title: "Advanced statistical analysis",
            description: "Perform complex statistical tests and regressions on your data",
          },
          {
            title: "Data visualization",
            description: "Create interactive charts and graphs for clear data representation",
          },
          {
            title: "Predictive modeling",
            description: "Utilize machine learning algorithms for future trend predictions",
          },
        ],
      },
    },
  ];
  const dispatch = useDispatch();
  const [selectedAgent, setSelectedAgent] = useState(agents[0]);

  return (
    <Layout className="min-h-screen" style={{ backgroundColor: selectedAgent.details.color }}>
      <div
        style={{
          background: selectedAgent.details.color,
          padding: "10px 0",
          textAlign: "center",
        }}
      >
        <Text style={{ color: "white" }}>Select one of our expert agents to proceed</Text>
      </div>
      <Content>
        <div style={{ background: "#fff", padding: "24px", minHeight: 280 }}>
          <Tabs defaultActiveKey="individual" centered style={{ maxWidth: 400, margin: "0 auto 24px" }}>
            <Tabs.TabPane tab="Solo Agents" key="individual" />
            <Tabs.TabPane tab="Agent Teams" key="sintra-x" />
          </Tabs>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "24px", width: "60%" }}>
              {agents.map((agent) => (
                <div
                  key={agent.name}
                  style={{ justifyContent: "center", textAlign: "center", margin: "0 4px" }}
                  onClick={() => setSelectedAgent(agent)}
                >
                  <div
                    style={{
                      width: 120,
                      height: 120,
                      borderRadius: "60px",
                      border: agent.name === selectedAgent.name ? "2px solid black" : "2px solid #d9d9d9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 32,
                      color: agent.name === selectedAgent.name ? selectedAgent.details.color : "#8c8c8c",
                      background: agent.name === selectedAgent.name ? `${selectedAgent.details.color}33` : "#f5f5f5",
                      cursor: "pointer",
                    }}
                  >
                    {agent.icon}
                  </div>
                  <div style={{ fontWeight:agent.name === selectedAgent.name ?"bold":"",marginTop: 8, wordWrap: "break-word", maxWidth: "120px", justifyContent: "center" }}>{agent.name}</div>
                </div>
              ))}
            </div>
          </div>

          <Card
  style={{
    maxWidth: 800,
    margin: "0 auto 24px",
    background: `linear-gradient(135deg, ${selectedAgent.details.color}FF, ${selectedAgent.details.color}33)`,
    boxShadow: `0 7px 10px ${selectedAgent.details.color}55`,
    borderRadius: "10px",
  }}
            bodyStyle={{ display: "flex" }}
          >
            <div style={{ display: "flex",flex:1 }}>
              <div style={{ color: "white" }}>
                <Title level={2} style={{ color: "white", marginBottom: 8 }}>
                  {selectedAgent.name}
                </Title>
                <ul style={{ color: "rgba(255,255,255,0.8)", paddingLeft: 20 }}>
                  {selectedAgent.details.description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end",flex:1 ,marginRight:'15px'}}>
              <Image preview={false} src={selectedAgent.details.image} style={{ height: "200px", borderRadius: "10px", marginLeft: "20px" }} />
            </div>
          </Card>

          <div style={{ maxWidth: 600, margin: "0 auto" }}>
            <Title level={3} style={{ marginBottom: 16 }}>
              {selectedAgent.details.title}
            </Title>
            <List
              itemLayout="horizontal"
              dataSource={selectedAgent.details.features}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          background: `${selectedAgent.details.color}22`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            background: selectedAgent.details.color,
                          }}
                        />
                      </div>
                    }
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </div>
          <div style={{ maxWidth: 400, margin: "24px auto 0", textAlign: "center" }}>
            <Button
              type="primary"
              size="large"
              block
              style={{ background: selectedAgent.details.color, borderColor: selectedAgent.details.color }}
              onClick={() => {
                dispatch(setUpdateLandingAgentSelection({ is_agent_selection: false, agent_selection_type: selectedAgent.name }));
              }}
            >
              Use Agent
            </Button>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
