import React from "react";
import { useDynamicSizeText } from "../../../../../hooks/useDynamicSizeText";
import { ModuleType } from "../types";
import { ContentNewtonState } from "../../../../../Store/reducers/_contentNewtonReducer";
import { useAppSelector } from "../../../../../Store/hooks";
import { isBrandMatch } from "../../../resources/helpers";

interface ModulePreviewTileProps {
  item: ModuleType;
  selectedItem?: ModuleType;
  setSelectedItem: Function;
}

export const ModulePreviewTile: React.FC<ModulePreviewTileProps> = ({ item, selectedItem, setSelectedItem }) => {
  const { modulesExtraInfo }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);
  const { ref } = useDynamicSizeText(6, 14);

  return (
    <div
      // ref={ref}
      style={{
        minHeight: "150px",
        maxHeight: "150px",
        width: "100%",
        padding: "10px",
        background: "#f0f7ff", //"#104862",
        color: "#5f5f5f",
        borderRadius: "8px",
        border: selectedItem?.id === item.id ? "3px solid #008fb4" : "3px solid transparent",
        boxShadow: selectedItem?.id === item.id ? "none" : "0 0 0 1px #0000001a, 0 0 0 2px transparent",
        opacity: selectedItem?.id === item.id ? 0.7 : 1,
        userSelect: "none",
        cursor: "pointer",
        overflow: "hidden",
      }}
      onClick={() => setSelectedItem(item)}
    >
      <div>
        <span style={{ fontWeight: "bold" }}>{isBrandMatch(modulesExtraInfo, "camzyos") ? "Claim" : "Module"} ID: </span>
        <span>
          {isBrandMatch(modulesExtraInfo, "camzyos")
            ? item.claims.find((claim) => claim.type?.toLowerCase() === "primary")?.claim_id
            : item.module_id}
        </span>
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>Primary Claim: </span>
        <span>{item.claims.find((claim) => claim.type?.toLowerCase() === "primary")?.text}</span>
      </div>
    </div>
  );
};
