import React from "react";
import { Card, Col, Image, Row } from "antd";
import "./ModulePreview.css"; // Make sure to create a CSS file for custom styles
import { ModuleType } from "../../types";
import { ContentNewtonState } from "../../../../../../Store/reducers/_contentNewtonReducer";
import { useAppSelector } from "../../../../../../Store/hooks";
import { isBrandMatch } from "../../../../resources/helpers";

interface Claim {
  id: string;
  description: string;
}

interface NonClaim {
  description: string;
}

interface Props {
  module: ModuleType;
  // moduleId: number;
  // claims: ModuleType["claims"];
  // nonClaims: ModuleType["nonclaims"];
  // images?: string[];
}

export const ModulePreview: React.FC<Props> = ({ module: { module_id, claims, nonclaims, images } }) => {
  const { modulesExtraInfo }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  // const extendedNonClaims = [...nonclaims];
  // for (let i = 0; i < 10; i++) {
  //   const randomIndex = Math.floor(Math.random() * nonclaims.length);
  //   extendedNonClaims.push({ ...nonclaims[randomIndex], id: nonclaims[randomIndex].id - i });
  // }
  const footnotes = new Set<string>();

  // Collect footnotes and references from claims
  claims?.forEach((claim) => {
    if (claim.footnote) footnotes.add(claim.footnote);
  });

  // Collect footnotes from images
  images?.forEach((image) => {
    if (image.footnote) footnotes.add(image.footnote);
  });

  return (
    <div className="module-preview-container">
      <div className="module-title">
        {isBrandMatch(modulesExtraInfo, "camzyos")
          ? `Claim: ${claims.find((claim) => claim.type?.toLowerCase() === "primary")?.claim_id}`
          : `Module: ${module_id}`}
      </div>

      <div className="content-container">
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Card title="Claims" className="scrollable">
              {claims?.map((claim) => (
                <div key={claim.id} className="claim-item">
                  <strong>Claim: {claim.type}</strong>
                  <p>{claim.text}</p>
                  {claim.reference && (
                    <p className="text-sm text-gray-500 mt-1">
                      <strong style={{ display: "block" }}>Reference:</strong>
                      {claim.reference}
                    </p>
                  )}
                </div>
              ))}
            </Card>
          </Col>
          {nonclaims?.length > 0 && (
            <Col span={24}>
              <Card title="Non Claims" className="scrollable">
                {nonclaims.map((nonClaim) => (
                  <div key={nonClaim.id} className="non-claim-item">
                    <strong>Non Claim: {nonClaim.claim_id}</strong>
                    <p>{nonClaim.text}</p>
                  </div>
                ))}
              </Card>
            </Col>
          )}
        </Row>

        <Row className="images-container">
          {images?.map((image, index) => (
            <Col span={24} key={index}>
              <Image key={index} src={image.url} alt={`Image ${image.image_id}`} style={{ border: "1px solid #0000001a" }} />
            </Col>
          ))}
        </Row>
        {footnotes?.size > 0 && (
          <Row className="images-container">
            <Col span={24}>
              <Card title="Footnotes" className="scrollable">
                <ol>
                  {[...footnotes].map((footnote, index) => (
                    <li>{footnote}</li>
                  ))}
                </ol>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
