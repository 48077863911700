import { useEffect, useState } from "react";
import { ContentNewtonState } from "../../../../../Store/reducers/_contentNewtonReducer";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { sseAddModules, sseListModules, sseReAddModules } from "../../../../../Store/requests/_contentNewtonRequests";
import { Button, notification, Skeleton } from "antd";
import { ModuleType, SuggesteModuleSSEData } from "../types";
import { SSEMessage, SSEMessageList } from "../../../components/common/DisplaySSEMessages";
import {
  setCNCurrentIndexData,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNCurrentIndexPartialData,
  setCNOrderId,
  setCNSystemSuggestedModulesList
} from "../../../../../Store/actions/_contentNewtonActions";
import { ModulesListing } from "../ModulesListing/ModulesListing";
import { ConversationStepsMapping, ConversationStepsName } from "../../../resources/constants";
import { SSMRendering } from "./SSMRendering";
import _localStorageService from "../../../../../Services/_localStorageService";
import { SSE } from "sse.js";

type SystemSuggestedModulesStepData = {
  errorMessage: string;
  retry: boolean;
  sseMessages: SSEMessage[];
  ssePostMessages: SSEMessage[];
  modulesList: SuggesteModuleSSEData["data"]["modules"];
  systemSuggestedModules: SuggesteModuleSSEData["data"]["system_suggested_modules"];
};

const systemSuggestedModulesStepDataInitial = {
  errorMessage: "",
  retry: false,
  sseMessages: [],
  ssePostMessages: [],
  modulesList: [],
  systemSuggestedModules: []
};

export const SystemSuggestedModules = ({ index }: { index: number }) => {
  const { currentStep, conversationSteps, orderId, selectedModuleIds }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  const stepData: SystemSuggestedModulesStepData = { ...systemSuggestedModulesStepDataInitial, ...(conversationSteps[index].data || {}) };

  console.log("SystemSuggestedModules", "stepData", stepData, "conversationSteps[index].data", conversationSteps[index].data);
  const initialState = { ...stepData, loading: !stepData.modulesList?.length, fatalError: false };

  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>(initialState.sseMessages);
  const [ssePostMessages, setSSEPostMessages] = useState<SSEMessage[]>(initialState.ssePostMessages); //Messages received after getting the suggested modules data
  const [modulesList, setModulesList] = useState<any[]>(initialState.modulesList);
  const [systemSuggestedModules, setSystemSuggestedModules] = useState<any[]>(initialState.systemSuggestedModules);
  const [loading, setLoading] = useState(initialState.loading);
  const [errorMessage, setErrorMessage] = useState(initialState.errorMessage);
  const [sseClosed, setSseClosed] = useState(!!initialState.modulesList?.length);
  const [retry, setRetry] = useState(initialState.retry); //For Re-selection of Modules
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (modulesList.length > 0 || conversationSteps[index].status === "Done") {
      return;
    }

    const sse = retry ? sseReAddModules(orderId, selectedModuleIds) : sseAddModules(selectedModuleIds, orderId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: SuggesteModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
        if (parsedData.data) {
          let { order_id, channel, modules, system_suggested_modules } = parsedData.data;
          dispatch(setCNOrderId(order_id));
          localStorage.setItem("contentNewtonChannel", channel);
          modules && dispatch(setCNSystemSuggestedModulesList({ order_id, modules, channel, system_suggested_modules }));
          dispatch(
            setCNCurrentIndexPartialData({ modulesList: modules, systemSuggestedModules: system_suggested_modules } as SystemSuggestedModulesStepData)
          );

          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          modules && setModulesList(modules);
          system_suggested_modules && setSystemSuggestedModules(system_suggested_modules);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: SuggesteModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      setSseClosed(true);

      if (parsedData?.message) {
        setSSEPostMessages((prev) => [...prev, parsedData.message, { type: "main" }]); //Blank message to finish the last step
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE System Suggested Modules Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [modulesList, retry]);

  useEffect(() => {
    if (sseMessages.length > 0) {
      dispatch(setCNCurrentIndexPartialData({ sseMessages }));
    }
  }, [sseMessages]);

  useEffect(() => {
    if (ssePostMessages.length > 0) {
      dispatch(setCNCurrentIndexPartialData({ ssePostMessages }));
    }
  }, [ssePostMessages]);

  const isSystemSuggestedModuleStep = ConversationStepsMapping[currentStep] === ConversationStepsName.MODULES_SYSTEM_SUGGESTED;
  const isDone = conversationSteps[index].status === "Done";

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          {!!modulesList.length && <SSMRendering modules={modulesList} systemSuggestedModules={systemSuggestedModules} />}
          <SSEMessageList sseMessages={ssePostMessages} />
          <div
            style={{
              display: orderId && !isDone && sseClosed ? "flex" : "none",
              justifyContent: "flex-end"
            }}
          >
            <Button type="primary" disabled={!modulesList.length} onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
