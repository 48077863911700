import { useEffect, useState } from "react";
import { RootState } from "../../../../Store";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { sseListModules } from "../../../../Store/requests/_contentNewtonRequests";
import { Button, notification, Skeleton } from "antd";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import { mockSSEMessages } from "./mockData";
import { ModulesListing } from "./ModulesListing/ModulesListing";
import { ModuleType } from "./types";
import {
  setCNAvailableModulesList,
  setCNCurrentIndexData,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNCurrentIndexPartialData,
  setCNGivenIndexData,
  setCNModulesExtraInfo,
  setCNOrderId,
  setCNPutStepsWithGivenNamesAfterCurrentIndex
} from "../../../../Store/actions/_contentNewtonActions";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { setSessionExpiredFlagAction } from "../../../../Store/actions/_authActions";
// import { Message, SSEMessageList } from "./DisplayAdminMessages";

export type MiscInfo = {
  brand_name: string; //"camzyos" | "opdivo"
};

type ListingModuleSSEData = {
  message: { text: string; type: "main" | "step" | "substep" };
  data: {
    modules: ModuleType[];
    no_of_modules: number;
    system_suggested_modules: ModuleType[];
    order_id: number;
    info: MiscInfo;
  };
  error: boolean;
};

export type ListingModuleStepData = {
  errorMessage: string;
  retry: boolean;
  sseMessages: SSEMessage[];
  modulesList: [];
  selectedModuleIds: number[];
  extraInfo: MiscInfo;
};

const listingModulesStepDataInitial = { errorMessage: "", retry: false, sseMessages: [], modulesList: [], selectedModuleIds: [] };

export const ModuleSelection = ({ index }: { index: number }) => {
  const { currentStep, conversationSteps, detailsInputPrompt, orderId }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  const stepData: ListingModuleStepData = { ...listingModulesStepDataInitial, ...(conversationSteps[index].data || {}) };

  // console.log()
  const initialState = { ...stepData, loading: !stepData.modulesList.length, fatalError: false };

  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>(initialState.sseMessages);
  const [modulesList, setModulesList] = useState<any[]>(initialState.modulesList);
  const [loading, setLoading] = useState(!initialState.errorMessage && initialState.loading);
  const [errorMessage, setErrorMessage] = useState(initialState.errorMessage);
  const [fatalError, setFatalError] = useState(initialState.fatalError);
  const [retry, setRetry] = useState(initialState.retry); //For retry on fatal error
  const [moduleSelectionLimt, setModuleSelectionLimt] = useState<number>(0);
  const [extraInfo, setExtraInfo] = useState<MiscInfo>(initialState.extraInfo);
  const dispatch = useAppDispatch();

  const handleRetry = () => {
    setRetry((p) => !p);
    resetComponentStates();
  };

  const resetComponentStates = () => {
    setLoading(initialState.loading);
    setErrorMessage(initialState.errorMessage);
    // setRetry(initialState.retry);
    setSSEMessages(initialState.sseMessages);
    setModulesList(initialState.modulesList);
  };

  useEffect(() => {
    if (modulesList.length > 0 || conversationSteps[index].status === "Done") {
      return;
    }

    fatalError && setFatalError(false);

    const sse = sseListModules(detailsInputPrompt, orderId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: ListingModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: ListingModuleSSEData;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
          dispatch(setCNCurrentIndexData({ errorMessage: parsedData.message.text }));
          dispatch(setCNPutStepsWithGivenNamesAfterCurrentIndex([ConversationStepsName.USER_PROMPT_INPUT, ConversationStepsName.MODULES_LISTING]));
          dispatch(setCNCurrentIndexDoneNextIndexProcess());
        }
        //Show the Modules Listing
        else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          console.log("parsedData.data.modules", parsedData.data.modules);
          setModulesList(parsedData.data.modules);
          setModuleSelectionLimt(parsedData.data.no_of_modules);
          setExtraInfo(parsedData.data.info);
          dispatch(setCNOrderId(parsedData.data.order_id));
          dispatch(setCNModulesExtraInfo(parsedData.data.info));
          console.log("parsedData.data.system_suggested_modules", parsedData.data.no_of_modules);
          // dispatch(setCNCurrentIndexData({ modulesList: parsedData.data } as ListingModuleStepData));
          // dispatch(setCNAvailableModulesList(parsedData.data.modules));
        }
      }
    });

    const originalErrorHandler = sse.onerror;

    sse.onerror = (err) => {
      if (originalErrorHandler) {
        originalErrorHandler.call(sse, err);
      }

      notification.error({ message: `SSE Module Listing Error!\n ${err}` });

      console.log("sse.onerror", { err });
      sse.close();
      setLoading(false);
      setFatalError(true);
    };
  }, [modulesList, retry]);

  useEffect(() => {
    if (sseMessages.length > 0) {
      dispatch(setCNCurrentIndexPartialData({ sseMessages }));
    }
  }, [sseMessages]);

  useEffect(() => {
    if (modulesList.length > 0) {
      dispatch(setCNCurrentIndexPartialData({ modulesList }));
      dispatch(setCNAvailableModulesList(modulesList));
    }
  }, [modulesList]);

  useEffect(() => {
    if (extraInfo) {
      dispatch(setCNCurrentIndexPartialData({ extraInfo }));
    }
  }, [extraInfo]);

  const isModuleSelectionStep = ConversationStepsMapping[currentStep] === ConversationStepsName.MODULES_LISTING;
  const isDone = conversationSteps[index].status === "Done";

  return (
    <div>
      {!fatalError &&
        (loading ? (
          <Skeleton active />
        ) : errorMessage ? (
          <div>{errorMessage}</div>
        ) : (
          <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
            <SSEMessageList sseMessages={sseMessages} />
            <ModulesListing modules={modulesList} stepData={stepData} selectionLimit={moduleSelectionLimt} extraInfo={extraInfo} />
            <div style={{ display: modulesList.length > 0 && !isDone ? "flex" : "none", justifyContent: "flex-end" }}>
              <Button type="primary" disabled={!stepData.selectedModuleIds.length} onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
                Proceed
              </Button>
            </div>
          </div>
        ))}
      {fatalError && (
        <div>
          <div>Error occured!</div>
          <Button type="link" onClick={handleRetry}>
            Retry
          </Button>
        </div>
      )}
    </div>
  );
};
