import { useEffect, useState } from "react";
import { RootState } from "../../../../Store";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { sseGetTemplates, sseListModules } from "../../../../Store/requests/_contentNewtonRequests";
import { Button, notification, Skeleton } from "antd";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import {
  setCNCurrentIndexData,
  setCNCurrentIndexDoneNextIndexProcess,
  setCNCurrentIndexPartialData,
  setCNFinalAssetTemplatesList,
  setCNGivenIndexData,
  setCNPutStepsWithGivenNamesAfterCurrentIndex,
  setCNSelectedTemplateId
} from "../../../../Store/actions/_contentNewtonActions";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { FinalAssetTemplateType, TemplateSelectionSSEResponse } from "../Module Selection/types";
import { TemplatesListing } from "./TemplatesListing/TemplatesListing";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { isBrandMatch } from "../../resources/helpers";
// import { Message, SSEMessageList } from "./DisplayAdminMessages";

export type TemplateSelectionStepData = {
  errorMessage: string;
  retry: boolean;
  sseMessages: SSEMessage[];
  templatesList: FinalAssetTemplateType[];
  selectedTemplateId: number;
};

const templateSelectionStepDataInitial = { errorMessage: "", retry: false, sseMessages: [], templatesList: [] };

export const TemplateSelection = ({ index }: { index: number }) => {
  const { currentStep, conversationSteps, orderId, selectedTemplateId, modulesExtraInfo }: ContentNewtonState = useAppSelector(
    (state) => state.contentNewtonReducer
  );

  const stepData: TemplateSelectionStepData = { ...templateSelectionStepDataInitial, ...(conversationSteps[index].data || {}) };

  const initialState = { ...stepData, loading: !stepData.templatesList.length, fatalError: false };

  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>(initialState.sseMessages);
  const [templatesList, setTemplatesList] = useState<any[]>(initialState.templatesList);
  const [loading, setLoading] = useState(initialState.loading);
  const [errorMessage, setErrorMessage] = useState(initialState.errorMessage);
  const dispatch = useAppDispatch();

  conversationSteps[index].status !== "Done" && console.log("templatesList", templatesList, "stepData", stepData);

  useEffect(() => {
    if (templatesList.length > 0 || conversationSteps[index].status === "Done") {
      return;
    }

    const sse = sseGetTemplates(orderId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: TemplateSelectionSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
        // parsedData = sseMessages[messageNumber];
        // setMessageNumber(messageNumber + 1);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: TemplateSelectionSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
        } else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          setTemplatesList(parsedData.data);
          dispatch(setCNFinalAssetTemplatesList(parsedData.data));
          dispatch(setCNCurrentIndexPartialData({ templatesList: parsedData.data } as TemplateSelectionStepData));
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Templates Listing Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [templatesList]);

  useEffect(() => {
    if (sseMessages.length > 0) {
      dispatch(setCNCurrentIndexPartialData({ sseMessages }));
    }
  }, [sseMessages]);

  const isTemplateSelectionStep = ConversationStepsMapping[currentStep] === ConversationStepsName.TEMPLATES_LISTING;

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          <TemplatesListing templateList={templatesList} selectedTemplateId={stepData.selectedTemplateId} />
          <div
            style={{
              display: conversationSteps[index].status === "Done" || !templatesList.length ? "none" : "flex",
              justifyContent: "flex-end",
              gap: "5px",
              marginTop: "5px"
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                dispatch(setCNSelectedTemplateId(0));
                dispatch(
                  setCNPutStepsWithGivenNamesAfterCurrentIndex([
                    ConversationStepsName.MODULES_LISTING,
                    ConversationStepsName.MODULES_SYSTEM_SUGGESTED,
                    ConversationStepsName.TEMPLATES_LISTING
                  ])
                );

                //Set next index data i.e. Modules_Listing step
                // dispatch(setCNGivenIndexData(index + 1, { retry: true }));
                //Set next index data i.e. MODULES_SYSTEM_SUGGESTED step
                dispatch(setCNGivenIndexData(index + 2, { retry: true }));

                dispatch(setCNCurrentIndexDoneNextIndexProcess());
              }}
            >
              Re-Select {isBrandMatch(modulesExtraInfo, "camzyos") ? "Claims" : "Modules"}
            </Button>
            <Button type="primary" disabled={!selectedTemplateId} onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
