import Lottie from "lottie-react";
import { FC, useEffect } from "react";
import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import { generateInfogramReport, updateInfogramReport } from "../../../../Store/requests/_semanticSearchRequests";
import { useSelector } from "react-redux";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { useDispatch } from "react-redux";
const { Paragraph } = Typography;



interface ReportGenerationLoadingProps {
    artifactsForReport?: any[]; // Add artifactsForReport prop
    templateID?:string;
    stepper?: number;
    setStepper?: React.Dispatch<React.SetStateAction<number>>;
    setWebUrl?:React.Dispatch<React.SetStateAction<string>>;
  }

export const ReportGenerationLoading: FC<ReportGenerationLoadingProps> = ({artifactsForReport,templateID,setStepper,stepper,setWebUrl}) => {
  const { t, i18n } = useTranslation();
  const dispatch=useDispatch();
  const {
      
          conversationId,
      
        } = useSelector((state: any) => state?.semanticSearchReducer);

        useEffect(() => {
            const fetchData = async () => {
              try {
                console.log("Starting API call...");
          
                // First API call
                const response1 = await generateInfogramReport({
                  conversation_id: conversationId,
                  user_context: "",
                  project_id: templateID || "",
                  artifact_id: artifactsForReport || [],
                });
          
                console.log("API 1 Response:", response1.data);

                if(response1.code===200){
                  const response2= await updateInfogramReport({
                    project_id: templateID || "",
                    template_data:response1.data
                  })
                  console.log(response2)
                  if(response2.code===200){
                    if(setWebUrl){
                      setWebUrl(response2?.data?.webUrl)
                    }
                    
                if (setStepper) {
                  setStepper(5);
                  return ;
                }
                  }
                }

              if(response1.code===400){

              
                if (setStepper) {
                  setStepper(3);
                  dispatch(setNotificationMsgAction("Content selected is not enough to generate a Report."))
                  return ;
                }
              }

              if (setStepper) {
                setStepper(5);
                dispatch(setNotificationMsgAction("There was error in content generation process . Please try again"))
              }
              
          
                // Check for error condition in response
          
                console.log("API call succeeded. Processing further...");
              } catch (error) {
                console.error("Error during API call:", error);
          
                if (setStepper) {
                  console.log("Setting stepper to 3 due to exception.");
                  setStepper(3);
                  dispatch(setNotificationMsgAction("There was error in content generation process . Please try again"))
                }
              }
            };
          
            // Call the fetchData function
            fetchData();
          
            // Ensure stepper is set in all cases
            // if (setStepper) {
            //   console.log("Setting stepper to 3 at end of useEffect.");
            //   setStepper(3);
            // }
          }, []);

  return (
    <Row gutter={[8, 8]} justify="center" align="middle">
      <Col span={24} className="d-flex justify-content-center align-items-center">
        <Lottie id="email" animationData={PROJECT_ICONS.SEMANTIC_LOADING_LOGO} loop={true} style={{ height: "23vh", width: "23vh" }} />
      </Col>
      <Col>
        <div
          style={{
            textAlign: "center",
            font: "normal normal normal 22px/30px Nunito Sans",
            letterSpacing: "0px",
            color: "#66676B",
            opacity: "1",
          }}
        >
Please Wait        </div>
        <div
          style={{
            textAlign: "center",
            font: "normal normal normal 22px/30px Nunito Sans",
            letterSpacing: "0px",
            color: "#66676B",
            opacity: "1",
          }}
        >
Content Generation for Report is in progress
        </div>
      </Col>
    </Row>
  );
};
