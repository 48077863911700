import { Button, Col, Dropdown, MenuProps, Modal, notification, Row, Spin } from "antd";
import { mockAssebleData, mockLocalisedImages } from "../mockData";
import { ImageType, LocalisedAssetType, OriginalAssetType } from "../AssetLocalisation";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CustomSelect } from "../../../components/common/Dropdowns/CustomSelect";
import { capitalizeFirstLetter } from "../../../../../Utils/_gFunctions/helpers";
import { useSyncedIframeScroll } from "../../../../../hooks/useSyncedIframeScroll";
import { FinalAssetPreviewModal } from "../../Asset Creation/FinalAssetPreviewModal";
import { FinalAsset } from "../../../resources/types";
import { BannerEditModal } from "./EditBannerModal/EditBannerModal";
import {
  saveLocalisedBanner,
  saveTranslatedBanner,
  saveAssestsBanner,
  sseGetAssetTranslatedBanner,
  sseGetAssetLocalisedBanner,
  saveUpdatedTranslatedAssetData,
  saveUpdatedLocalisedAssetData
} from "../../../../../Store/requests/_contentNewtonRequests";
import { useAppSelector } from "../../../../../Store/hooks";
import { RootState } from "../../../../../Store";

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;
export interface BannerEditData {
  heading: {
    heading: {
      id: number;
      text: string;
      wait_time: number;
      cta: {
        url?: string;
        text?: string;
      };
    };
    htmlcontent: string;
    html_id: string;
  };
  frames: [
    {
      frame: {
        id: number;
        wait_time: number;
        content: string;
        cta?: {
          url?: string;
          text?: string;
        };
      };
      htmlcontent: string;
      html_id: string;
    }
  ];
  common: {
    video: string;
    references: string[];
    footnotes: string[];
  };
}
export const AssembleModal: React.FC<{
  open: boolean;
  setOpen: Function;
  originalAsset: OriginalAssetType[];
  localisedAssets: LocalisedAssetType[];
  setAssets?: Dispatch<SetStateAction<LocalisedAssetType[]>>;
  translation?: boolean;
  disableEdit?: boolean;
}> = ({ open, setOpen, originalAsset, localisedAssets, setAssets, translation, disableEdit }) => {
  const { modulesExtraInfo } = useAppSelector((state: RootState) => state.contentNewtonReducer);

  console.log({ originalAsset, localisedAssets });
  const [channel, setChannels] = useState<string>(localStorage.getItem("contentNewtonChannel") || "email");
  const [asset, setAsset] = useState<OriginalAssetType[]>();
  const [localisedAssetList, setLocalisedAssetList] = useState<LocalisedAssetType[]>([]);
  const [markets, setMarkets] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>("");
  const [dimensions, setDimensions] = useState<string[]>([]);
  const [selectedDimension, setSelectedDimension] = useState<string>("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditModalBanner, setOpenEditModalBanner] = useState(false);
  const { iframe1Ref, iframe2Ref } = useSyncedIframeScroll(open);
  const [bannerEditData, setBannerEditData] = useState<BannerEditData>();
  const [bannerLoader, setBannerLoader] = useState(false);

  console.log("assets101", asset, localisedAssetList);

  //@ts-ignore
  //   localisedAssets = mockAssebleData;

  useEffect(() => {
    setAsset(originalAsset);
  }, [originalAsset]);

  useEffect(() => {
    setLocalisedAssetList(localisedAssets);
    const updatedMarkets = getMarkets();
    if (channel === "banner") {
      const updatedDimension = getDimensions();
      setDimensions(updatedDimension);
      setSelectedDimension(updatedDimension[0] || "");
      // setDimensions(updatedDimension);
    }
    setMarkets(updatedMarkets);
    setSelectedMarket(updatedMarkets[0] || "");
  }, [localisedAssets]);

  useEffect(() => {
    const filteredData = localisedAssets.filter((asset) => asset.market?.toLowerCase() === selectedMarket?.toLowerCase());
    setLocalisedAssetList(filteredData);
  }, [selectedMarket]);

  function getMarkets() {
    return Array.from(new Set(localisedAssets.map((asset) => asset.market && capitalizeFirstLetter(asset.market))));
  }

  function getDimensions() {
    return Array.from(
      new Set(
        localisedAssets
          .map((asset) => {
            if (asset?.width && asset?.height) {
              return `${asset.width}x${asset.height}`;
            }
            return "";
          })
          .filter(Boolean)
      )
    );
  }

  // const getLocalisedAsset = () => {
  //   return localisedAssetList.find((asset) => asset.market?.toLowerCase() === selectedMarket?.toLowerCase());
  // };

  const getLocalisedAsset = () => {
    if (channel === "banner") {
      const [width, height] = selectedDimension.split("x").map(String);
      return localisedAssetList.find(
        (asset) => asset.market?.toLowerCase() === selectedMarket?.toLowerCase() && asset?.width === width && asset?.height === height
      );
    }
    return localisedAssetList.find((asset) => asset.market?.toLowerCase() === selectedMarket?.toLowerCase());
  };

  const localisedAsset = getLocalisedAsset() ?? ({} as LocalisedAssetType);

  const getOriginalAsset = () => {
    if (channel === "banner") {
      const [width, height] = selectedDimension.split("x").map(String);
      console.log(
        "test101",
        originalAsset.find((asset) => asset.width === width && asset.height === height),
        width,
        height,
        originalAsset
      );
      return originalAsset.find((asset) => asset.width === width && asset.height === height);
    }
    return originalAsset[0];
  };

  const oncancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    setOpen(false);
  };

  const fetchEditBannerData = async () => {
    setBannerLoader(true);

    let sse: any;
    if (translation) {
      sse = sseGetAssetTranslatedBanner(localisedAsset.id);
    } else {
      sse = sseGetAssetLocalisedBanner(localisedAsset.id);
    }

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setBannerLoader(false);

      let Data: any;
      try {
        Data = JSON.parse(data.data);
        console.log("Data101", Data);
        if (!Data?.data) {
          notification.error({ message: "Error parsing banner data" });
          return;
        }
        setBannerEditData(Data.data);
        setOpenEditModalBanner(true);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        notification.error({ message: "Error parsing banner data" });
        // sse.close();
        return;
      }
    });

    sse.onerror = (err: any) => {
      notification.error({ message: `SSE Error While Getting Data!\n ${err}` });
      sse.close();
      setBannerLoader(false);
    };
  };

  const handleEdit = () => {
    if (channel === "email") {
      setOpenEditModal(true);
    } else {
      // Call the API for banner
      fetchEditBannerData();
    }
  };

  const getImageComponent = (url: string) => {
    return (
      <Col span={12}>
        <img src={url} width={"100%"} style={{ borderRadius: "10px", border: "1px lightgray solid" }} />
      </Col>
    );
  };

  const downloadMenuitems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a style={{ fontSize: "12px" }} onClick={() => downloadFile(localisedAsset?.html, "html", assembledAssetFileName)}>
          HTML
        </a>
      )
    }
  ];

  const downloadFile = async (content: string, type: string, fileName?: string) => {
    var element = document.createElement("a");

    if (type === "pdf") {
      element.setAttribute("href", content);
      element.setAttribute("download", "Result.pdf"); // would not affect the final filename
    }
    if (type === "html") {
      element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(content));
      element.setAttribute("download", `${fileName}.html` || "Result.html");
    }

    element.click();
  };

  const assembledAssetFileName = modulesExtraInfo?.brand_name
    ? `${capitalizeFirstLetter(modulesExtraInfo?.brand_name?.toLowerCase())}_${capitalizeFirstLetter(selectedMarket)}_${
        translation ? capitalizeFirstLetter(localisedAsset?.language) : "English"
      }`
    : translation
    ? "TranslatedAsset"
    : "LocalisedAsset"; //'Camzyos_Germany_English'

  return (
    <Modal
      keyboard={false}
      open={open}
      className="cn-fullscreen-modal"
      title={
        <span style={{ fontSize: "large", paddingLeft: ".7rem" }}>
          <b>Assembly : </b>
          <span style={{ fontWeight: "lighter" }}>Localised Asset {translation ? "with" : "without"} Translation</span>
        </span>
      }
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
      styles={{
        body: {
          overflow: "hidden",
          //   border: "1px lightgray solid",
          borderRadius: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px"
        }
      }}
    >
      <Row
        gutter={[16, 16]}
        style={{
          width: "100%",
          display: "flex",
          gap: "1rem" // or "16px"
        }}
      >
        <Col xs={24} sm={24} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <CustomSelect
            w="20rem"
            selectedTag={selectedMarket}
            optionsList={markets}
            placeholder="Select"
            onChange={(val: string) => setSelectedMarket(val)}
            fieldName="Market"
          />
          {channel === "banner" && (
            <CustomSelect
              w="20rem"
              selectedTag={selectedDimension}
              optionsList={dimensions}
              placeholder="Select"
              onChange={(val: string) => setSelectedDimension(val)}
              fieldName="Dimension"
            />
          )}
        </Col>
      </Row>
      <Row style={{ width: "100%", fontWeight: "bold" }}>
        <Col span={12}>Original Asset</Col>
        <Col span={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>{translation ? "Translated" : "Localised"} Asset</div>
          <div>
            <Button
              type="link"
              loading={bannerLoader}
              onClick={() => handleEdit()}
              style={{ height: 0, padding: 0, marginRight: "16px", color: "rgba(3, 78, 162, 1)", display: disableEdit ? "none" : "unset" }}
            >
              Edit
            </Button>
            <Dropdown menu={{ items: downloadMenuitems }} placement="bottomLeft" trigger={["click"]}>
              <span
                onClick={(e) => e.preventDefault()}
                style={{
                  fontWeight: 500,
                  color: "rgba(3, 78, 162, 1)",
                  marginRight: "8px",
                  cursor: "pointer",
                  border: "1px solid #d9d9d9",
                  padding: "4px 10px",
                  borderRadius: "4px"
                }}
              >
                Download
              </span>
            </Dropdown>
          </div>
        </Col>
      </Row>
      {/* <div style={{ overflow: "auto", width: "100%" }}> */}
      <Row gutter={[8, 8]} style={{ width: "100%", flex: 1 }}>
        <Col span={12}>
          <iframe
            ref={iframe1Ref}
            srcDoc={scrollBarStyles + getOriginalAsset()?.html}
            style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }}
          />
        </Col>
        <Col span={12}>
          <iframe
            ref={iframe2Ref}
            srcDoc={scrollBarStyles + localisedAsset?.html}
            style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }}
          />
        </Col>
      </Row>
      {/* </div> */}
      {localisedAsset?.html && (
        <FinalAssetPreviewModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          asset={{ ...localisedAsset, asset_id: localisedAsset?.id } as FinalAsset}
          edit={true}
          updateAsset={(updatedContent: string) => {
            setAssets?.((prevAssets) => {
              return prevAssets.map((asset) => {
                if (asset.id === localisedAsset?.id) {
                  return { ...asset, html: updatedContent };
                }
                return asset;
              });
            });
          }}
          updateAssetAPI={translation ? saveUpdatedTranslatedAssetData : saveUpdatedLocalisedAssetData}
          fileName={assembledAssetFileName} //{translation ? "TranslatedAsset" : "LocalisedAsset"}
          disableEdit={disableEdit}
        />
      )}
      {localisedAsset?.html && asset && bannerEditData && (
        <BannerEditModal
          open={openEditModalBanner}
          saveBannerApi={translation ? saveTranslatedBanner : saveLocalisedBanner}
          setOpen={setOpenEditModalBanner}
          setAsset={(updatedContent: string, id: number) => {
            setLocalisedAssetList?.((prevAssets) => {
              return prevAssets.map((locasset) => {
                if (localisedAsset?.id === id) {
                  return { ...locasset, html: updatedContent };
                }
                return locasset;
              });
            });
          }}
          editData={bannerEditData}
          id={localisedAsset.id}
        />
      )}
    </Modal>
  );
};
