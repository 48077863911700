import { FC, useEffect, useState } from "react";
import { getAllArtifacts, getArtifactByFileName } from "../../../../Store/requests/_semanticSearchRequests";
import { ChatModalScreen } from "../SearchQueryScreens/_ChatModalScreen";
import { Col, Row, Spin } from "antd";
import { useDispatch } from "react-redux";
import { ArtifactsWrapper } from "../Artifacts/_ArtifactsWrapper";
import { TemplateSelectionScreen } from "./_template-selection-screen";
import { TemplateDescriptionScreen } from "./_template-description-screen";
import { TemplateGeneratedText } from "./_template-generated-text";
import { ReportGenerationLoading } from "./_report-content-generation-loader";
import { useSelector } from "react-redux";


export const ReportGenerationWrapper: FC = () => {
    const {
    
        conversationId,
    
      } = useSelector((state: any) => state?.semanticSearchReducer);
  const dispatch = useDispatch();
  const [stepperForReportGeneration, setStepperForReportGeneration] = useState(1);
  const [artifactsForReport,setArtifactsForReport]=useState<any[]>([]);
  const [templateID,setTemplateID]=useState<string>("39c177bd-a567-419b-a5e8-bdb2fed48145")
  const [webUrl,setWebUrl]=useState<string>("");

  console.log(artifactsForReport)
  console.log(templateID)
  console.log(conversationId)
  console.log(stepperForReportGeneration)

  return (
    <>
      {stepperForReportGeneration === 1 ? (
        <>
          <ArtifactsWrapper
            stepper={stepperForReportGeneration}
            setStepper={setStepperForReportGeneration}
            artifactsForReport={artifactsForReport}
            setArtifactsForReport={setArtifactsForReport}
          />
        </>
      ) : stepperForReportGeneration === 2 ? (
        <>
          <Row style={{ padding: "5vh 10vh" }} gutter={[16, 16]}>
            <TemplateSelectionScreen
              stepper={stepperForReportGeneration}
              setStepper={setStepperForReportGeneration}
              templateID={templateID}
              setTemplateID={setTemplateID}
            />
          </Row>
        </>
      ) : stepperForReportGeneration === 3 ? (
        <>
          <Row style={{ padding: "5vh 10vh" }} gutter={[16, 16]}>
            <TemplateDescriptionScreen
              stepper={stepperForReportGeneration}
              setStepper={setStepperForReportGeneration}
            />
          </Row>
        </>
      ) : stepperForReportGeneration === 4 ? (
        <>
        <Row gutter={[16, 24]} style={{ height: "83vh" }}>
                        <Col span={24} className="d-flex justify-content-center align-items-center">
                          <ReportGenerationLoading  artifactsForReport={artifactsForReport} templateID={templateID}  stepper={stepperForReportGeneration}
              setStepper={setStepperForReportGeneration} setWebUrl={setWebUrl}/>
                        </Col>
                      </Row>
        </>
      ) : stepperForReportGeneration === 5 ? (
        <>
          <Row style={{ padding: "5vh 10vh" }} gutter={[16, 16]}>
            <TemplateGeneratedText
              stepper={stepperForReportGeneration}
              setStepper={setStepperForReportGeneration}
              webUrl={webUrl}
              setWebUrl={setWebUrl}
              templateID={templateID}
            />
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
