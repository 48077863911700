import React, { LegacyRef, MutableRefObject, useEffect, useRef } from "react";
import "./AdminMessage.css";
import { ConversationStep } from "../../../resources/types";
import { Button } from "antd";
import { ConversationStatus, ConversationStepsMapping, ConversationStepsName, PointerEventsEnabledSteps } from "../../../resources/constants";
import { useAppSelector } from "../../../../../Store/hooks";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";

interface AdminMessageProps {
  chatData: ConversationStep;
  footerButtonsOnClick: { [key in string]: () => void };
  children?: React.ReactNode;
}

const AdminMessage: React.FC<AdminMessageProps> = ({ chatData, footerButtonsOnClick, children }) => {
  const { currentStep, currentStepLoading } = useAppSelector((state) => state.contentNewtonReducer);
  const currentElRef = useRef<HTMLDivElement>();
  const { timestamp, titleMessage, footerMessage, type, footerButtonText } = chatData;

  // useEffect(() => {
  //   if (currentElRef.current && currentStep === chatData.step) {
  //     setTimeout(() => {
  //       currentElRef.current &&
  //         currentElRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }, 100);
  //   }
  // }, [currentStep]);

  useEffect(() => {
    if (currentElRef.current && currentStep === chatData.step && skipScrollingForTheseSteps()) {
      setTimeout(() => {
        currentElRef.current && currentElRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [currentElRef, currentStepLoading]);

  const skipScrollingForTheseSteps = () => {
    // if (ConversationStepsMapping[currentStep] === ConversationStepsName.MLR_ASSETS_SUBMISSION) return false;
    return true;
  };

  const enablePointerEventsForTheseSteps = () => {
    return PointerEventsEnabledSteps.includes(ConversationStepsMapping[chatData.step]);
  };

  return (
    <div
      className="admin-message-container"
      style={{
        pointerEvents: chatData.status === ConversationStatus.DONE && !enablePointerEventsForTheseSteps() ? "none" : "auto"
      }}
      ref={currentElRef as LegacyRef<HTMLDivElement>}
    >
      <div className="avatar-container">
        <img src={PROJECT_ICONS.CN_ADMIN_NEXT_ICON} alt="Admin Avatar" className="avatar" />
      </div>
      <div className="message-container">
        <div className="timestamp">{timestamp}</div>
        <div className="message-content">
          {titleMessage && <div className="message" dangerouslySetInnerHTML={{ __html: titleMessage || "" }}></div>}
          <div className="content">{children}</div>
          {footerMessage && (
            <div
              className="message"
              style={{
                display: currentStep === chatData.step && currentStepLoading ? "none" : "flex"
              }}
              dangerouslySetInnerHTML={{ __html: footerMessage || "" }}
            ></div>
          )}
          {footerButtonText && footerButtonText.length && (
            <div
              className="footer"
              style={{
                display: chatData.status === ConversationStatus.DONE || currentStepLoading ? "none" : "flex"
              }}
            >
              {footerButtonText?.map((text) => (
                <Button key={text} type="primary" onClick={footerButtonsOnClick[text]}>
                  {text}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminMessage;
