import { StepProps } from "antd";
import { ConversationStep, StepperStatusKeys } from "./types";
import { formatDateTime } from "../../../Utils";

export const FooterButtonsText = {
  NEXT: "Next",
  UPLOAD_INSTEAD: "Upload Instead",
  PROCEED: "Proceed",
  SKIP: "Skip",
  CANCEL: "Cancel",
  DEFINE_FOR_ENGLISH: "Define for English",
  GENERATE: "Generate",
  PUBLISH: "Publish",
  SUBMIT_SELECTED_ASSETS: "Send Selected for MLR Review"
};

export const ConversationStatus = {
  DONE: "Done",
  PROCESS: "Process",
  WAIT: "Wait"
} as const;

export const StepperStepStatus: Record<StepperStatusKeys, StepProps["status"]> = {
  WAIT: "wait",
  PROCESS: "process",
  FINISH: "finish",
  ERROR: "error"
};

export const UserType = {
  ADMIN: "admin",
  USER: "user"
};

//Don't change the order if ConversationStepsMapping is being derived using Object.keys()!
export const ConversationStepsName = {
  INITIAL_MESSAGE_DETAILS: "Initial Message Details",
  USER_PROMPT_INPUT: "User Prompt Input",
  // RETRY_STEP: "Retry Step",
  MODULES_LISTING: "Modules Listing",
  RESELECT_MODULES: "Reselect Modules",
  MODULES_SYSTEM_SUGGESTED: "Modules System Suggested",
  USER_PROCEED_TO_TEMPLATE_SELECTION: "User Proceed to Template Selection",
  TEMPLATES_LISTING: "Templates Listing",
  USER_PROCEED_TO_FINAL_ASSET_CREATION: "User Proceed to Final Asset Creation",
  CREATING_FINAL_ASSET: "Creating Final Asset",
  USER_PROCEED_TO_LOCALISATION: "User Proceed to Localisation",
  LOCALISATION_MARKET_AND_LANGUAGE: "Localisation Market and Language",
  USER_PROMPT_MARKET_AND_LANGUAGE: "User Prompt Market and Language",
  LOCALISATION_WITHOUT_TRANSLATION: "Localisation Without Translation",
  USER_PROCEED_TO_TRANSLATION: "User Proceed to Translation",
  TRANSLATION_LANGUAGE: "Translation Language",
  USER_PROMPT_TRANSLATION_LANGUAGE: "User Prompt Translation Language",
  LOCALISATION_WITH_TRANSLATION: "Localisation With Translation"
};

export const CoversationStepsList = [
  ConversationStepsName.INITIAL_MESSAGE_DETAILS,
  ConversationStepsName.USER_PROMPT_INPUT,
  ConversationStepsName.MODULES_LISTING,
  ConversationStepsName.MODULES_SYSTEM_SUGGESTED,
  ConversationStepsName.USER_PROCEED_TO_TEMPLATE_SELECTION,
  ConversationStepsName.TEMPLATES_LISTING,
  ConversationStepsName.USER_PROCEED_TO_FINAL_ASSET_CREATION,
  ConversationStepsName.CREATING_FINAL_ASSET,
  ConversationStepsName.USER_PROCEED_TO_LOCALISATION,
  ConversationStepsName.LOCALISATION_MARKET_AND_LANGUAGE,
  ConversationStepsName.USER_PROMPT_MARKET_AND_LANGUAGE,
  ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION,
  ConversationStepsName.USER_PROCEED_TO_TRANSLATION,
  ConversationStepsName.TRANSLATION_LANGUAGE,
  ConversationStepsName.USER_PROMPT_TRANSLATION_LANGUAGE,
  ConversationStepsName.LOCALISATION_WITH_TRANSLATION
];

//TODO: Don't depend on the order of keys in ConversationStepsName. Use array for order.
export const ConversationStepsMapping: Record<string, string> = {
  "1": ConversationStepsName.INITIAL_MESSAGE_DETAILS,
  "2": ConversationStepsName.USER_PROMPT_INPUT,
  "3": ConversationStepsName.MODULES_LISTING,
  "4": ConversationStepsName.MODULES_SYSTEM_SUGGESTED,
  "5": ConversationStepsName.USER_PROCEED_TO_TEMPLATE_SELECTION,
  "6": ConversationStepsName.TEMPLATES_LISTING,
  "7": ConversationStepsName.USER_PROCEED_TO_FINAL_ASSET_CREATION,
  "8": ConversationStepsName.CREATING_FINAL_ASSET,
  "9": ConversationStepsName.USER_PROCEED_TO_LOCALISATION,
  "10": ConversationStepsName.LOCALISATION_MARKET_AND_LANGUAGE,
  "11": ConversationStepsName.USER_PROMPT_MARKET_AND_LANGUAGE,
  "12": ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION,
  "13": ConversationStepsName.USER_PROCEED_TO_TRANSLATION,
  "14": ConversationStepsName.TRANSLATION_LANGUAGE,
  "15": ConversationStepsName.USER_PROMPT_TRANSLATION_LANGUAGE,
  "16": ConversationStepsName.LOCALISATION_WITH_TRANSLATION
};

export const ConversationStepNametoStepNumberMapping: Record<string, number> = Object.fromEntries(
  Object.entries(ConversationStepsMapping).map(([stepNumber, stepName]) => [stepName, parseInt(stepNumber)])
);

export const LastConversationStep = Math.max(...Object.keys(ConversationStepsMapping).map(Number));

// export const LastConversationStep = Object.keys(ConversationStepsMapping).reduce(
//   (last, current) => (parseInt(current) > last ? parseInt(current) : last),
//   1,
// );

// export const LastConversationStep = (() => {
//   let maxStep = -Infinity;
//   for (const key of Object.keys(ConversationStepsMapping)) {
//     const numKey = parseInt(key, 10);
//     if (numKey > maxStep) {
//       maxStep = numKey;
//     }
//   }
//   return maxStep;
// })();

export const ConversationStepsMessages: Record<string, string | { first?: string; second?: string }> = {
  [ConversationStepsName.INITIAL_MESSAGE_DETAILS]: "Create an email with me with these details: Brand, Market, Key message and Language.",
  [ConversationStepsName.RESELECT_MODULES]: "Re-select modules.",
  [ConversationStepsName.USER_PROCEED_TO_TEMPLATE_SELECTION]: "Yes, Proceed.",
  [ConversationStepsName.USER_PROCEED_TO_FINAL_ASSET_CREATION]: "Yes, Proceed.",
  [ConversationStepsName.USER_PROCEED_TO_LOCALISATION]: "Yes, Proceed.",
  [ConversationStepsName.LOCALISATION_MARKET_AND_LANGUAGE]: "Please provide the market as well as language for localization",
  [ConversationStepsName.USER_PROCEED_TO_TRANSLATION]: "Yes, Proceed.",
  [ConversationStepsName.TRANSLATION_LANGUAGE]: "Please provide the language for Translation"

  // [ConversationStepsName.PERSONA_SELECTION]: {
  //   first: `Ok! Now lets personalise your assets as per <b>Spanish</b> Language:<br/>`,
  //   second: `<label><input type="checkbox" /> Apply the selected personas for all languages</label>`
  // }, //Spanish
  // [ConversationStepsName.ASSET_ELEMENT_IDENTIFIED]: {
  //   second: "Would you like to proceed to localisation?"
  // },
};

export const ConversationStepsFooterButtonsText: Record<string, string[]> = {
  // [ConversationStepsName.MODULES_LISTING]: [FooterButtonsText.PROCEED],
  // [ConversationStepsName.MODULES_SYSTEM_SUGGESTED]: [FooterButtonsText.PROCEED],
  // [ConversationStepsName.TEMPLATES_LISTING]: [FooterButtonsText.PROCEED],
  // [ConversationStepsName.CREATING_FINAL_ASSET]: [FooterButtonsText.PROCEED]
  // [ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION]: [FooterButtonsText.PROCEED]
  // [ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION]: [FooterButtonsText.CANCEL, FooterButtonsText.PROCEED],
};

export const conversationSteps: ConversationStep[] = [
  {
    step: 1,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage: "Let's start by defining the following asset parameters:",
    componentName: "AssetParametersSelection",
    footerButtonText: [FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.DONE // "done", "process"
  },
  {
    step: 2,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage:
      "Share a reference artefact that you would want to replicate, I have integration with Veeva Vault PromoMats. Do you want to pull assets or upload your assets?",
    componentName: "ArtefactsSourceSelection",
    footerButtonText: [FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.DONE // "done", "process"
  },
  {
    step: 3,
    type: UserType.USER, //"user",
    timestamp: "2:05 PM, 15 Nov",
    titleMessage: "Search From VVPM",
    status: ConversationStatus.PROCESS // "done", "process"
  }
];

export function generateConversationSteps(): ConversationStep[] {
  const steps: ConversationStep[] = [];
  Object.entries(ConversationStepsMapping).forEach(([step, name], index) => {
    // console.log({ step, name });
    let componentName = "";
    let footerButtonText: string[] = ConversationStepsFooterButtonsText[name];
    let status = index === 0 ? ConversationStatus.PROCESS : ConversationStatus.WAIT;

    const getMessages = () => {
      let firstMessage, secondMessage;
      if (!ConversationStepsMessages[name]) return [firstMessage, secondMessage];
      if (typeof ConversationStepsMessages[name] !== "string") {
        firstMessage = (ConversationStepsMessages[name] as any).first;
        secondMessage = (ConversationStepsMessages[name] as any).second;
      } else {
        firstMessage = ConversationStepsMessages[name] as string;
      }
      return [firstMessage, secondMessage];
    };

    const [titleMessage, footerMessage] = getMessages();

    steps.push({
      step: parseInt(step),
      stepName: name,
      type: name.startsWith("User") ? UserType.USER : UserType.ADMIN,
      timestamp: formatDateTime(), //"2:04 PM, 15 Nov",
      titleMessage,
      footerMessage,
      componentName,
      footerButtonText,
      status
    });
  });

  return steps;
}

export const CONVERSATION_STEPS = generateConversationSteps();

console.log({ CONVERSATION_STEPS });

export const StepperStepsName = {
  INPUT_PROMPT: "Input Prompt",
  MODULE_SELECTION: "Module Selection",
  TEMPLATE_SELECTION: "Template Selection",
  ASSET_CREATION: "Asset Creation",
  LOCALISATION: "Localisation",
  TRANSLATION: "Translation"
};

//TODO: Use array for order
export const StepperSteps: Record<number, string> = {
  0: StepperStepsName.INPUT_PROMPT,
  1: StepperStepsName.MODULE_SELECTION,
  2: StepperStepsName.TEMPLATE_SELECTION,
  3: StepperStepsName.ASSET_CREATION,
  4: StepperStepsName.LOCALISATION,
  5: StepperStepsName.TRANSLATION
};

export const StepperInitalState: StepProps[] = Object.keys(StepperStepsName).reduce((steps, step, index) => {
  steps.push({
    title: StepperStepsName[step as keyof typeof StepperStepsName],
    status: index === 0 ? "process" : "wait"
  });
  return steps;
}, [] as StepProps[]);

console.log({ StepperInitalState });

export const StepperConversationStepsMapping: Record<string, string[]> = {
  [StepperStepsName.INPUT_PROMPT]: [ConversationStepsName.INITIAL_MESSAGE_DETAILS, ConversationStepsName.USER_PROMPT_INPUT],
  [StepperStepsName.MODULE_SELECTION]: [ConversationStepsName.MODULES_LISTING, ConversationStepsName.USER_PROCEED_TO_TEMPLATE_SELECTION],
  [StepperStepsName.TEMPLATE_SELECTION]: [ConversationStepsName.TEMPLATES_LISTING, ConversationStepsName.USER_PROCEED_TO_FINAL_ASSET_CREATION],
  [StepperStepsName.ASSET_CREATION]: [ConversationStepsName.CREATING_FINAL_ASSET, ConversationStepsName.USER_PROCEED_TO_LOCALISATION],
  [StepperStepsName.LOCALISATION]: [ConversationStepsName.LOCALISATION_MARKET_AND_LANGUAGE, ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION],
  [StepperStepsName.TRANSLATION]: [ConversationStepsName.USER_PROCEED_TO_TRANSLATION, ConversationStepsName.LOCALISATION_WITH_TRANSLATION]
};

export const PointerEventsEnabledSteps = [
  ConversationStepsName.MODULES_SYSTEM_SUGGESTED,
  ConversationStepsName.CREATING_FINAL_ASSET,
  ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION,
  ConversationStepsName.LOCALISATION_WITH_TRANSLATION
];
