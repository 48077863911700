import React, { useState, useEffect, useRef } from 'react'
import { Tabs, Form, Input, Button, Modal, Row, Col, Card, Typography } from 'antd'
import type { TabsProps } from 'antd'
import "./EditBannerModal.css";
import { BannerEditData } from "../AssembleModal"
import { EyeOutlined } from '@ant-design/icons';

const { Title } = Typography;
interface BannerEditProps {
  editData: BannerEditData;
  open: boolean;
  setOpen: (open: boolean) => void;
  id: number;
  setAsset: Function | null;
  saveBannerApi: Function;
}

interface BannerResponse {
  data: {
    html: string;
  };
}

export function BannerEditModal({ editData: data, open, setOpen, id, setAsset, saveBannerApi }: BannerEditProps) {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('heading');
  const [loading, setLoading] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [editData, setEditData] = useState(data)

  useEffect(() => {
    setEditData(data)
    setActiveTab('heading')
  }, [open])

  useEffect(() => {
    const defaultFrame = {
      id: 0,
      wait_time: 0,
      content: '',
      cta: { url: '', text: '' }
    };

    const formValues = {
      heading: editData.heading?.heading || { text: '', wait_time: 0, cta: { url: '', text: '' } },
      frames: editData.frames && editData.frames.length > 0
        ? editData.frames.map(frame => frame.frame)
        : [defaultFrame, defaultFrame]
    };

    form.setFieldsValue(formValues);

    // Set initial iframe content
    if (iframeRef.current) {
      iframeRef.current.srcdoc = editData.heading.htmlcontent;
    }
  }, [editData, form, open]);

  const updateIframeContent = (values: any) => {
    if (!iframeRef.current || !iframeRef.current.contentDocument) return;

    const doc = iframeRef.current.contentDocument;

    if (values.heading) {
      const headingElement = doc.querySelector('[data="heading"]');
      if (headingElement) {
        headingElement.textContent = values.heading.text;
      }
    }

    if (values.frames) {
      values.frames.forEach((frame: any, index: number) => {
        if (frame) {
          const claim_id = `claim-${index + 1}`;
          const buttonId = `button-${index + 1}`;

          if (frame.content || frame.content === '') {
            const selected_claim = doc.getElementById(claim_id);
            if (selected_claim) {
              selected_claim.textContent = frame.content;
            }
          }

          if (frame.cta) {
            if (frame.cta.text) {
              const buttonDiv = doc.getElementById(buttonId);
              const pTag = buttonDiv?.querySelector("p");
              if (pTag) {
                pTag.textContent = frame.cta.text;
              }
            }
            if (frame.cta.url) {
              const buttonDiv = doc.getElementById(buttonId);
              const anchorTag = buttonDiv?.closest("a");
              if (anchorTag) {
                anchorTag.href = frame.cta.url;
              }
            }
          }
        }
      });
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    updateIframeContent(allValues);
  };

  useEffect(() => {
    if (iframeRef.current && open) {
      const content = activeTab === 'heading'
        ? editData.heading.htmlcontent
        : editData.frames[parseInt(activeTab.split('-')[1]) - 1].htmlcontent;

      iframeRef.current.srcdoc = content;
    }
  }, [open, activeTab, editData]);

  const onFinish = async (values: any) => {
    try {
      const updatedData = {
        heading: {
          id: editData.heading?.heading?.id || 0,
          text: values.heading?.text || '',
          wait_time: editData.heading?.heading?.wait_time || 0,
          cta: values.heading?.cta
        },
        frames: (values.frames || []).map((frame: any, index: number) => ({
          id: editData.frames?.[index]?.frame?.id || 0,
          wait_time: editData.frames?.[index]?.frame?.wait_time || 0,
          content: frame?.content || '',
          cta: frame?.cta
        })),
        common: {
          video: editData.common?.video || "",
          references: editData.common?.references || [],
          footnotes: editData.common?.footnotes || []
        }
      };
      let res: BannerResponse = await saveBannerApi(id, updatedData);

      if (res.data.html) {
        console.log("res102", res.data.html)
        if (setAsset) {
          setAsset(res.data.html, id)
        }
      }

    } catch (err) {
      console.error(err);
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      await onFinish(values);
      setOpen(false);
    } catch (err) {
      console.error('Validate Failed:', err);
    } finally {
      setLoading(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'heading',
      label: 'Heading',
      forceRender: true,
      children: (
        <HeadingTab
          form={form}
          htmlContent={editData.heading.htmlcontent || ''}
        />
      ),
    },
    ...(editData.frames && editData.frames.length > 0 ? editData.frames : [{}, {}]).map((frameData, index) => ({
      key: `frame-${index + 1}`,
      label: `Frame ${index + 1}`,
      forceRender: true,
      children: (
        <FrameTab
          form={form}
          index={index}
          // @ts-ignore
          frameData={frameData || { frame: {}, htmlcontent: '' }}
          // @ts-ignore
          htmlContent={frameData.htmlcontent || ''}
        />
      ),
    })),
  ];

  return (
    <Modal
      open={open}
      className="cn-fullscreen-variable-width-modal edit-banner"
      title="Banner Edit"
      onCancel={onCancel}
      onOk={onOk}
      okText={loading ? "Saving..." : "Save"}
    >
      <Row gutter={[24, 24]} style={{ height: '100%', marginLeft: 0, marginRight: 0 }}>
        {/* Editor Section */}
        <Col span={12}>
          <Card bordered={false} bodyStyle={{ padding: "10px" }}>
            <Form
              form={form}
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              layout="vertical"
            >
              <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}
                items={items}
                style={{ width: '100%' }}
              />
            </Form>
          </Card>
        </Col>

        {/* Preview Section */}
        <Col span={12}>
          <Card
            bordered={false}
            title={
              <Title level={5} style={{ margin: 0 }}>
                Preview {activeTab === 'heading' ? 'Heading' : `Frame ${activeTab.split('-')[1]}`}
              </Title>
            }
            bodyStyle={{
              padding: '12px',
              height: '70vh',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px'
            }}
          >
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <iframe
                ref={iframeRef}
                style={{
                  flex: '1 1 auto',
                  border: 'none',
                  borderRadius: '4px',
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
}





interface HeadingTabProps {
  form: any;
  htmlContent: string;
}

function HeadingTab({ form, htmlContent }: HeadingTabProps) {
  return (
    <div>
      <Form.Item label="Heading" name={['heading', 'text']}>
        <Input placeholder="Enter heading" />
      </Form.Item>
    </div>
  )
}

interface FrameTabProps {
  form: any;
  index: number;
  frameData: BannerEditData['frames'][0];
  htmlContent: string;
}

function FrameTab({ form, index, frameData, htmlContent }: FrameTabProps) {
  return (
    <div>
      <Form.Item label="Content" name={['frames', index, 'content']}>
        {/* <Input placeholder="Enter content" /> */}
        <Input.TextArea placeholder="Enter content" rows={4} style={{ resize: 'none' }} />
      </Form.Item>
      {frameData?.frame?.cta && (
        <>
          <Form.Item label="CTA Text" name={['frames', index, 'cta', 'text']}>
            <Input placeholder="Enter CTA text" />
          </Form.Item>
          <Form.Item label="CTA URL" name={['frames', index, 'cta', 'url']}>
            <Input placeholder="Enter CTA URL" />
          </Form.Item>
        </>
      )}
    </div>
  )
}

