export const SET_CN_STEPPER_CURRENT_STEP = "SET_CN_STEPPER_CURRENT_STEP";
export const SET_CN_STEPPER_STEPS = "SET_CN_STEPPER_STEPS";
export const SET_CN_CURRENT_STEPPER_STEP_FINISH = "SET_CN_CURRENT_STEPPER_STEP_FINISH";
export const SET_CN_CURRENT_STEPPER_STEP_FINISH_NEXT_STEP_PROCESS = "SET_CN_CURRENT_STEPPER_STEP_FINISH_NEXT_STEP_PROCESS";
export const SET_CN_CONVERSATION_STEPS = "SET_CN_CONVERSATION_STEPS";
export const SET_CN_CURRENT_STEP = "SET_CN_CURRENT_STEP";
export const SET_CN_CURRENT_STEP_DONE = "SET_CN_CURRENT_STEP_DONE";
export const SET_CN_CURRENT_STEP_DONE_NEXT_STEP_PROCESS = "SET_CN_CURRENT_STEP_DONE_NEXT_STEP_PROCESS";
export const SET_CN_CURRENT_STEP_DONE_GIVEN_STEP_PROCESS = "SET_CN_CURRENT_STEP_DONE_GIVEN_STEP_PROCESS";
export const SET_CN_CURRENT_STEP_DATA = "SET_CN_CURRENT_STEP_DATA";
export const SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_STEP = "SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_STEP";
export const SET_CN_CURRENT_STEP_LOADING = "SET_CN_CURRENT_STEP_LOADING";
export const SET_CN_CURRENT_STEP_TIMESTAMP = "SET_CN_CURRENT_STEP_TIMESTAMP";

export const SET_CN_CURRENT_INDEX = "SET_CN_CURRENT_INDEX";
export const SET_CN_CURRENT_INDEX_DONE = "SET_CN_CURRENT_INDEX_DONE";
export const SET_CN_CURRENT_INDEX_DONE_NEXT_INDEX_PROCESS = "SET_CN_CURRENT_INDEX_DONE_NEXT_INDEX_PROCESS";
export const SET_CN_CURRENT_INDEX_DATA = "SET_CN_CURRENT_INDEX_DATA";
export const SET_CN_CURRENT_INDEX_PARTIAL_DATA = "SET_CN_CURRENT_INDEX_PARTIAL_DATA";
export const SET_CN_GIVEN_INDEX_DATA = "SET_CN_GIVEN_INDEX_DATA";
export const SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_INDEX = "SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_INDEX";
export const SET_CN_PUT_STEPS_WITH_GIVEN_NAMES_AFTER_CURRENT_INDEX = "SET_CN_PUT_STEPS_WITH_GIVEN_NAMES_AFTER_CURRENT_INDEX";
export const SET_CN_CURRENT_INDEX_LOADING = "SET_CN_CURRENT_INDEX_LOADING";
export const SET_CN_CURRENT_INDEX_TIMESTAMP = "SET_CN_CURRENT_INDEX_TIMESTAMP";

export const SET_CN_DETAILS_INPUT_PROMPT = "SET_CN_DETAILS_INPUT_PROMPT";
export const SET_CN_AVAILABLE_MODULES_LIST = "SET_CN_AVAILABLE_MODULES_LIST";
export const SET_CN_SELECTED_MODULE_IDS = "SET_CN_SELECTED_MODULE_IDS";
export const SET_CN_ORDER_ID = "SET_CN_ORDER_ID";
export const SET_CN_MODULES_EXTRA_INFO = "SET_CN_MODULES_EXTRA_INFO";
export const SET_CN_SYSTEM_SUGGESTED_MODULES_LIST = "SET_CN_SYSTEM_SUGGESTED_MODULES_LIST";
export const SET_CN_FINAL_ASSET_TEMPLATES_LIST = "SET_CN_FINAL_ASSET_TEMPLATES_LIST";
export const SET_CN_SELECTED_TEMPLATE_ID = "SET_CN_SELECTED_TEMPLATE_ID";
export const SET_CN_FINAL_ASSET = "SET_CN_FINAL_ASSET";
export const SET_CN_MARKET_AND_LANGUAGE_INPUT_PROMPT = "SET_CN_MARKET_AND_LANGUAGE_INPUT_PROMPT";
export const SET_CN_LOCALISED_ASSET_DATA = "SET_CN_LOCALISED_ASSET_DATA";
export const SET_CN_TRANSLATION_LANGUAGE_INPUT_PROMPT = "SET_CN_TRANSLATION_LANGUAGE_INPUT_PROMPT";
export const SET_CN_TRANSLATED_ASSET_DATA = "SET_CN_TRANSLATED_ASSET_DATA";

export const RESET_CN_STATES = "RESET_CN_STATES";
