import { FC, useEffect, useState } from "react";
import { getAllArtifacts, getArtifactByFileName } from "../../../../Store/requests/_semanticSearchRequests";
import { ChatModalScreen } from "../SearchQueryScreens/_ChatModalScreen";
import { Col, Row, Spin } from "antd";
import { useDispatch } from "react-redux";
import { NoArtefactsScreen } from "./_NoArtefactsScreen";
import { GeneralArtifacts } from "./_GeneralArtifacts";
import { ArtifactsHead } from "./_ArtifactsHead";
import { FileArtefactsWrapper } from "./FileAretfacts/_FileAretfactsWrapper";
import { CommonArtefactsWrapper } from "./CommonAretfacts/_CommonArtefactsWrapper";

interface ArtifactsWrapperProps {
  name?: string; // Optional prop
  stepper?: number;
  setStepper?: React.Dispatch<React.SetStateAction<number>>;
  artifactsForReport?: any[]; // Add artifactsForReport prop
  setArtifactsForReport?: React.Dispatch<React.SetStateAction<any[]>>;
}
interface LoadDataType {
  isLoading: boolean;
  message: string;
};

export const ArtifactsWrapper: FC<ArtifactsWrapperProps> = ({ name,setStepper,stepper ,artifactsForReport,setArtifactsForReport}) => {
  const dispatch = useDispatch();
  const [isLoadingArtifactsData, setIsLoadingArtifactsData] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
  const [currentArtifactFile, setIsArtifactFile] = useState<string>("");
  const [fileSpecificArtifact, setFileSpecificArtifact] = useState<any>([]);
  const [commonArtifacts, setCommonArtifacts] = useState<any>([]);
  const [artifactSwitch, setArtifactSwitch] = useState<number>(2);
  const [loadData, setLoadData] = useState<LoadDataType>({
    isLoading: false,
    message: "both",
  });
  const loadArtifactsData = async () => {
    setIsLoadingArtifactsData(true);

    let resCommon, resFile;

    // Check the message in loadData to determine which APIs to call
    if (loadData.message !== "file" ) {
    //     // Load common artifacts only if the message is "both" or "common"
        resCommon = await getAllArtifacts();
        if (resCommon) {
            setCommonArtifacts(resCommon.data.commonArtefacts);
            console.log(resCommon?.data)
        }
      }

    if(loadData.message !== "common"){
        resFile = await getArtifactByFileName({ file_name: currentArtifactFile }); 
        if (resFile) {
            setFileSpecificArtifact(resFile.data.fileArefacts);
        }
      }
    

    // Set artifactSwitch based on the results
    if (!resCommon && !resFile) {
        setArtifactSwitch(3);
    } else if (!resCommon) {
        setArtifactSwitch(2);
    } else if (!resFile) {
        setArtifactSwitch(1);
    }

    setTimeout(() => {
        setIsLoadingArtifactsData(false);
        setLoadData({ isLoading: false, message: "" });
    }, 2000); // Delay of 2000 milliseconds (2 seconds)
};


  useEffect(() => {
    if (loadData.isLoading) {
      loadArtifactsData(); // Call the async function when the component mounts
    }
  }, [loadData.isLoading]);

  return (
    <>
      {isModalVisible ? (
        <ChatModalScreen
          setIsModalVisible={setIsModalVisible}
          handleAdvancedSearchClose={() => setIsModalVisible(false)}
          setIsArtifactFile={setIsArtifactFile}
          setLoadData={setLoadData}
          loadData={loadData}
        />
      ) : loadData.isLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "300px" }}>
          <Spin />
        </div>
      ) : (
        artifactSwitch === 3 ? (
            
          <NoArtefactsScreen setIsModalVisible={setIsModalVisible} />
        ) : (
            <>
                  <Row style={{ padding: "2vh 11vh" }} gutter={[8, 8]}>

            <Col xs={{ span: 24 }}>
                <ArtifactsHead />
              </Col>
              {artifactSwitch===2?(
          <FileArtefactsWrapper
          setIsModalVisible={setIsModalVisible}
          handleAdvancedSearchClose={() => setIsModalVisible(false)}       
            artifacts={currentArtifactFile}
            setIsArtifacts={setIsArtifactFile}
            fileSpecificArtefact={fileSpecificArtifact}
            setFileSpecificArtefact={setFileSpecificArtifact}
            commonArtefacts={commonArtifacts}
            setCommonArtefacts={setCommonArtifacts}
            switchForArtefact={artifactSwitch}
            setSwitchForArtefacts ={setArtifactSwitch}
            setLoadData={setLoadData}

          />):(<>
         <CommonArtefactsWrapper
          setIsModalVisible={setIsModalVisible}
          handleAdvancedSearchClose={() => setIsModalVisible(false)}       
            artifacts={currentArtifactFile}
            setIsArtifacts={setIsArtifactFile}
            fileSpecificArtefact={fileSpecificArtifact}
            setFileSpecificArtefact={setFileSpecificArtifact}
            commonArtefacts={commonArtifacts}
            setCommonArtefacts={setCommonArtifacts}
            switchForArtefact={artifactSwitch}
            setSwitchForArtefacts ={setArtifactSwitch}
            setLoadData={setLoadData}
             setStepperForReport={setStepper}
             stepperForReport={stepper}
             artifactsForReport={artifactsForReport}
             setArtifactsForReport={setArtifactsForReport}
            />
          </>)}
          </Row>
          </>
        )
      )}
    </>
  );
};
