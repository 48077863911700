export const siteConfig = {
  BASE_URL: process.env.REACT_APP_API_URL,
  S3_BASE_URL: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/icons",

  //LOCAL STORAGE PROPERTIES
  ACCESS_TOKEN: "access_token",
  CT_PROCESS_KEY: "ct_process_key",
  SESSION_EXPIRED: "session_expired",
  ACCEPT_LANGUAGE: "accept_language",

  //Query Param Keys

  //ENDPOINTS PROPERTIES
  // UPLOAD_EMAIL_DOC: "/upload-email-doc",
  UPLOAD_EMAIL_DOC: "/file",
  // UPLOAD_EMAIL_DASHBOARD: "/dashboard",
  UPLOAD_EMAIL_DASHBOARD: "/file",
  CREATE_HTML: "/create-html",
  CREATE_HTML_DASHBOARD: "/dashboard",
  // CREATE_STORYBOARD: "/create-storyboard",
  // STORYBOARD_DASHBOARD: "/dashboard",
  // CREATE_STORYBOARD: "/storyboard",
  CREATE_STORYBOARD: "/storyboardurl",
  STORYBOARD_DASHBOARD: "/storyboard",
  // GET_IMAGES: "/get-images",
  GET_IMAGES: "/visuals",
  // GET_IMAGES_DASHBOARD: "/images",
  GET_IMAGES_DASHBOARD: "/visuals",
  // GET_IMAGES_DASHBOARD: "/storyboard",
  GET_TEMPLATES: "/templates",
  GET_AVATARS: "/avatars",
  // CREATE_VIDEO: "/create-video",
  CREATE_VIDEO: "/videourl/create",
  GET_VIDEO: "/video",
  SAVE_VOICE: "/video/voice",
  EDIT_STORYBOARD: "/storyboard",
  EDIT_DOCUMENT: "/email/edit",
  // IMAGE_UPLOAD: "/image-upload",
  IMAGE_UPLOAD: "/visuals/upload",
  DELETE_FILE: "/file",
  GET_LIVE_IMAGES: "/search/image",
  GET_LIVE_VIDEOS: "/search/video",
  SAVE_LIVE_VIDEO: "/search/video",
  SAVE_LIVE_IMAGE: "/search/image",
  GET_PLATFORM_LIST: "/platforms",
  //Change Avatar
  CHANGE_AVATAR: "/video/avatar",
  RENDER: "/video/render",
  GET_DURATION: "/voice/duration",
  GET_VOICE_LIST: "/voices",
  // FETCH_AUDIO: "/voices/duration",
  FETCH_AUDIO: "/voices",

  //StoryBoard Conversation
  STORYBOARD_CONVERSATION: "/conversation",
  STORYBOARD_CONVERSATION_CHAT: "/conversation/chat",
  STORYBOARD_CHAT_COMPARE: "/compare",
  STORYBOARD_CHAT_APPLY: "/apply",

  //Semantic Search
  FILTER_FOLDER: "/filters",
  GET_SIMILAR_DOCS: "/document/similarity-search",
  GET_SIMILARITY_SEARCH: "/document/similarity-search",
  GET_SUMMARISED_DOC: "/document",
  ADD_TO_REPORT: "/similarity-search/report/add",
  GENERATE_REPORT: "/document//similarity-search/report/generate",
  GET_REPORT_BY_ID: "/get-report-by-id",
  GET_DOCUMENT_FILTER: "/document/filters",
  SEMANTIC_SEARCH: "/semantic-search",
  GET_DOCUMENT: "/get-document",
  GET_DASHBOARD: "/dashboard",
  CHAT_DOCUMENT: "/document/chat",
  FOLDER: "/folder/0",
  UPLOAD: "/document/upload",
  DELETE_DOCUMENT: "/delete-document",
  GET_CHAT: "/document/chat",
  GET_QUESTION_RECOMMENDATION: "/document/recommendation",
  START_CONVERSATION: "/conversation",
  GET_INSIGHT_HISTORY: "/billing/insight",
  GET_CHAT_HISTORY: "/billing/chat",
  GET_BILLING: "/billing",
  GET_BILLING_REPORT: "/billing/download",
  IMAGEFOLDER: "/folder/0",
  IMAGEUPLOAD: "/image/upload/0",
  UPLOADFILES: "/upload/0",
  VIDEOUPLOAD: "/video/upload/0",
  GET_IMAGE_DOCUMENT_FILTER: "/image/filters",
  GET_IMAGE_DRAWER: "/image",
  GET_MESSAGE: "/conversation/message",
  PRO_MODE: "/document/conversation/generate-tags",
  GET_MESSAGE_INTERESTED: "/document/conversation",
  UPDATE_REPORT_PPT: "/document/report/slide",
  GENERATE_PDF: "/document/report/generate/pdf",
  UPDATE_REPORT_PDF: "/document/report/pdf",
  GET_CHUNKS: "/document/conversation/chunks",
  SYNCS3: "/document/sync-s3",
  GET_ALL_NOTIFICATION: "/notification/all",
  UPDATE_READ_STATUS_FOR_NOTIFICATION: "/notification",
  ARTIFACT: "/artifact",
  EXPORT_ARTIFACT: "/document/export/artifact",
  UPLOADBATCHQA: "/upload",

  MEDICALREFERENCECHUNKS: "/document/fetch-chunks",
  GET_TAVILY_CHUNKS:"/conversation/tavily-sources",

  GENERATE_INFOGRAM_TEMPLATE:"/document/generate-infogram-report",
  UPDATE_INFOGRAM_TEMPLATE:"/document/update-infogram-template",
  SCHEDULE_INFOGRAM_TASK:"/document/schedule-infogram-task",
  //content history
  // GET_HISTORY_STATUS: "/history",
  GET_HISTORY_STATUS: "/search",
  GET_HISTORY_FILTER_LIST: "/ui/status ",
  GET_LIST_OF_LANGUAGES: "/languages",
  SAVE_TRANSLATE_LANGUAGE: "/translate",
  GET_ELAI_RESPONSE_DATE: "/elai/video",
  GET_ELAI_VIDEOS: "/elai/videos",
  GET_FILE_UPLOADS: "/uploads",
  IMAGE_FILE_UPLOADS: "/uploads/image",
  VIDEO_FILE_UPLOADS: "/uploads/video",

  //CT with blog functionality
  CREATE_BLOG_URL: "/get-blog-details",
  GET_STORYBOARD_ID: "/get-storyboard-id",
  UPDATE_BLOG_VIDEO: "/update-blog-video",
  CREATE_PREDIS_POST: "/predis",

  VIDEO_SUMMARY: "/video_summary",
  SIMILARITY_SEARCH_INTERESTED: "/document/similarity-search",
  SEMANTIC_QA_INTERESTED: "/semantic-qa",

  SEMANTIC__IMAGE_SEARCH: "/image/search",
  GET_IMAGE_SEMANTIC: "/image",
  SEMANTIC_IMAGE_INTERESTED: "/image",
  //Content AI
  CREATE_PROMPT: "/prompt",
  GET_ALL_TAGS: "/tag/all",
  GET_REGION: "/tag/region",
  GET_BRAND: "/tag/brands",
  GET_CHANNEL: "/tag/channel",
  ENRICH_PROMPT: "/prompt/enrich_prompt",
  UPDATE_RULE: "/rule/update",
  DELETE_RULE: "/rule/delete",
  ADD_RULE: "/rule/add",
  FILTER_RULES: "/filter/",
  ADD_PROMPT_RULES: "/prompt/add_rules",
  CU_UPDATE_CONTENT: "/content_universe/update",
  CU_DELETE_CONTENT: "/content_universe/delete",
  CU_UPLOAD_FILE: "/content_universe/upload",

  //I detail extract api constants
  IDX_UPLOAD_FILE: "/file",
  IDX_LIST_KEY_MESSAGE: "/file",
  IDX_CREATE_CLAIMS: "/claims",
  IDX_UPDATE_CLAIMS: "/claims",
  IDX_GET_CLAIMS: "/claims",
  IDX_UPDATE_CATEGORIES: "/claims",
  IDX_ASSET_TYPES: "/assettype",
  IDX_CREATE_MAIN_ASSET: "/asset",
  IDX_GET_MAIN_ASSET: "/asset",
  IDX_UPLOAD_IMAGE: "/image",
  IDX_DOWNLOAD: "/download",

  // Translation module api constants
  TM_GET_LANGUAGE_LIST: "/language",
  TM_UPLOAD_FILE: "/file",
  TM_DELETE_FILE: "/file",
  TM_GLOSSARY: "/glossary",
  TM_TRANSLATION_TEXT: "/translation/text",
  TM_TRANSLATION_FILE: "/translation/file",
  TM_UPDATE_TRANSLATION: "/translation",
  TM_TRANSLATION_FILE_REVOKE: "/revoke",
  TM_GLOSSARY_UPLOAD_EXCEL: "/glossary/upload-excel",

  //Content Newton Module
  CN_LIST_MODULES: "/module",
  CN_GET_TEMPLATES: "/template",
  CN_LOCALISE_ASSETS: "/localise",
  CN_GET_LOCALISED_ASSETS: "/localise/asset",
  CN_FINAL_ASSET: "/assets",
  CN_TRANSLATE_ASSETS: "/translate",
  CN_GET_TRANSLATED_ASSETS: "/translate/asset",
  CN_EDIT_ASSET_BANNER: "/assets/editbanner",
  CN_EDIT_LOCALISE_BANNER: "/localise/editbanner",
  CN_EDIT_TRANSLATE_BANNER: "/translate/editbanner",


};
