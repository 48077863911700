import { Button, Card, Col, Image, Modal, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../../Assets/Img/_DIcons";
import TextArea from "antd/es/input/TextArea";
import { exportArtefactReport, getArtifactById, UpdateArtifactData } from "../../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { LeftCircleOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;


interface LoadDataType {
  isLoading: boolean;
  message: string;
};

interface TemplateDescriptionProps {
    stepper?: number;
    setStepper?: React.Dispatch<React.SetStateAction<number>>;
  }

export const TemplateDescriptionScreen: FC<TemplateDescriptionProps> = ({stepper,setStepper}) => {
  const { tabList, filecardType } = useSelector(
    (state: any) => state?.semanticSearchReducer
  );
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [items, setItems] = useState<string[]>([""]);
  const [selectedFormat, setSelectedFormat] = useState("Export as PPT");
  const [numColumns, setNumColumns] = useState<number>(0);
  const [numRows, setNumRows] = useState<number>(5);
  const [generatedSchema, setGeneratedSchema] = useState<boolean>(false);

  const [selectedCardId, setSelectedCardId] = useState<number | null>(0);
  const [selectedCardPdfId,setSelectedCardPdfId]=useState<number|null>(0);
  const [imageWidth, setImageWidth] = useState(910); // Initial width is set to 910
  const [imageHeight , setImageHeight] = useState(450)




const handleCardSelection = (cardId: number) => {
  setSelectedCardId(cardId);
};
const handleCardPDFSelection = (cardId:number) =>{
  setSelectedCardPdfId(cardId)
}


  const PPTData = [
    [
      { id: 1, card: "main", backgroundImage: PROJECT_ICONS.PPTMAIN },
      { id: 2, card: "1", backgroundImage: PROJECT_ICONS.PPT1 },
      { id: 3, card: "2", backgroundImage: PROJECT_ICONS.PPT2 },
    ],
    [
    
      { id: 4, card: "3", backgroundImage: PROJECT_ICONS.PPT3 },
      { id: 5, card: "4", backgroundImage: PROJECT_ICONS.PPT4 },
      { id: 6, card: "5", backgroundImage: PROJECT_ICONS.PPT5 },
    ]
  ];

  return (
 <>
 <Col span={7}>
    <Card className="card-element">
   <div style={{fontWeight:'700',fontSize:'18px'}}>
    Template Name
   </div>
    </Card>
    </Col>
    <Col span={17}>
    <Card className="card-element">
        <div style={{display:'flex',justifyContent:'space-between'}}>
    <div style={{fontWeight:'700',fontSize:'18px'}}>
    Overview
   </div>
   <Button style={{background:'#1E4DA1',color:'white'}} onClick={() => {
      if (setStepper) {
        setStepper(4);
      } else {
        console.warn("Stepper is not defined.");
      }
    }}>PROCEED</Button>


   </div>
   <div style={{display:'flex',marginTop:'10px'}}>
    <div style={{flex:'2'}}>n</div>
    <div style={{flex:'1'}}>n</div>
</div>
    </Card>
    </Col>
 </>
  );
};
